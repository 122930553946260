<template>
  <div class="navbarWrapper" v-if="!loading">
    <nav class="navbar navbar-expand-lg">
      <router-link to="/" class="navbar-brand ml-xs-0 ml-lg-4" alt="Tjing logo">
        <Logo />
      </router-link>

      <div
        @click="toggleMobileMenu()"
        id="nav-icon3"
        class="d-lg-none"
        :class="{ open: showMobileMenu || isAModalOpen }"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-if="!isUserLoggedIn">
            <router-link to="/whytjing" class="nav-link"
              >Why Tjing?</router-link
            >
          </li>
          <li class="nav-item" v-if="isUserLoggedIn">
            <router-link to="/dashboard" class="nav-link"
              >Dashboard</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/events/" class="nav-link">Events</router-link>
          </li>

          <li class="nav-item dropdown" ref="host" v-if="isUserLoggedIn">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Host
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="svg-inline--fa fa-chevron-down fa-w-14 dropdownArrow"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                />
              </svg>
            </a>
            <div
              class="dropdown-menu dropdown-menuWithAnimation animate fadeIn"
              aria-labelledby="navbarDropdown"
            >
              <router-link to="/manage" class="dropdown-item animate slideIn">
                <img
                  class="dropdown-menuItem-icon"
                  src="@/assets/img/personInGearIconWithBorder.svg"
                />
                <div>
                  <div class="dropdown-itemTjingCustom">Manager</div>
                  <p class="dropdown-itemTjingCustomSubtitle">
                    Everything you need to host events
                  </p>
                </div>
              </router-link>
              <router-link to="/builder" class="dropdown-item animate slideIn">
                <img
                  class="dropdown-menuItem-icon"
                  src="@/assets/img/craneGoalIconWithBorder.svg"
                />
                <div>
                  <div class="dropdown-itemTjingCustom">Builder</div>
                  <p class="dropdown-itemTjingCustomSubtitle">
                    Create any type of event
                  </p>
                </div>
              </router-link>
            </div>
          </li>
          <li class="nav-item">
            <a
              href="http://shop.tjing.se"
              target="_blank"
              class="nav-link"
              rel="noopener"
              >Shop</a
            >
          </li>
          <li class="nav-item dropdown" ref="resources">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownResource"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Resources
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="svg-inline--fa fa-chevron-down fa-w-14 dropdownArrow"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                />
              </svg>
            </a>
            <div
              class="dropdown-menu dropdown-menuWithAnimation animate fadeIn"
              aria-labelledby="navbarDropdownResources"
            >
              <a href="/faq" class="dropdown-item animate slideIn">
                <img
                  class="dropdown-menuItem-icon"
                  src="@/assets/img/faqIcon.svg"
                />
                <div>
                  <div class="dropdown-itemTjingCustom">FAQ</div>
                  <p class="dropdown-itemTjingCustomSubtitle">
                    Questions we get asked most about
                  </p>
                </div>
              </a>
              <a
                href="https://support.tjing.se"
                class="dropdown-item animate slideIn"
              >
                <img
                  class="dropdown-menuItem-icon"
                  src="@/assets/img/folderIconWithBorder.svg"
                />
                <div>
                  <div class="dropdown-itemTjingCustom">Tjing Docs</div>
                  <p class="dropdown-itemTjingCustomSubtitle">
                    In-depth documentation
                  </p>
                </div>
              </a>
              <a href="/legal" class="dropdown-item animate slideIn">
                <img
                  class="dropdown-menuItem-icon"
                  src="@/assets/img/TermsAndConditionIconWithBorder.svg"
                />
                <div>
                  <div class="dropdown-itemTjingCustom">Privacy and Terms</div>
                  <p class="dropdown-itemTjingCustomSubtitle">
                    What we collect and legal agreements
                  </p>
                </div>
              </a>

              <!-- <router-link to="/#" class="dropdown-item animate slideIn">
                <img
                  class="dropdown-menuItem-icon"
                  src="@/assets/img/downloadArrowIconWithBorder.svg"
                />
                <div>
                  <div class="dropdown-itemTjingCustom">Download</div>
                  <p class="dropdown-itemTjingCustomSubtitle">XXX</p>
                </div>
              </router-link>-->
            </div>
          </li>
          <!-- <li v-if="foundLiveEvents" class="nav-item liveEvent">
            <router-link to="/events/upcoming" class="nav-link">
              Live
              <LiveEventIndicator />
            </router-link>
          </li>-->
        </ul>

        <router-link
          v-if="isUserLoggedIn && showMarshallBanner"
          :to="{ name: 'scorecard' }"
        >
          <div class="marshall-scorecard">Marshall scorecard</div>
        </router-link>

        <router-link
          v-if="isUserLoggedIn && showScorecardBanner"
          :to="{ name: 'scorecard' }"
        >
          <div class="active-scorecard">Your scorecard</div>
        </router-link>

        <ul class="navbar-nav">
          <li v-if="!isUserLoggedIn" class="nav-item">
            <router-link
              :to="{ name: 'login', params: { source: 'home' } }"
              class="nav-link mr-4"
              >Log in</router-link
            >
          </li>
          <li v-if="isUserLoggedIn" class="nav-item dropdown ml-5 mr-3">
            <a
              class="nav-link dropdown-toggle circle"
              href="#"
              id="profileDropdownProfile"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ $store.getters.userInitials }}</a
            >
            <div
              class="dropdown-menu profile-menu"
              aria-labelledby="navbarDropdownProfile"
            >
              <router-link to="/profile" class="dropdown-item"
                >Profile settings</router-link
              >
              <a @click="$store.dispatch('logout')" class="dropdown-item"
                >Log out</a
              >
            </div>
          </li>
          <div class="buttonSection" v-if="!isUserLoggedIn">
            <div class="buttonContainer">
              <router-link :to="{ name: 'register' }">
                <button class="shampoo signUpButtonMenu">Sign up free</button>
              </router-link>
            </div>
          </div>
        </ul>
      </div>
    </nav>

    <router-link
      v-if="isUserLoggedIn && showMarshallBanner"
      :to="{ name: 'scorecard' }"
    >
      <div class="marshall-scorecard-mobile d-lg-none">Marshall scorecard</div>
    </router-link>

    <router-link
      v-if="isUserLoggedIn && showScorecardBanner"
      :to="{ name: 'scorecard' }"
    >
      <div class="active-scorecard-mobile d-lg-none">Your scorecard</div>
    </router-link>
    <div
      class="loadingbar"
      :class="{ active: $store.state.utility.loading }"
    ></div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  components: {
    Logo,
  },
  data: function () {
    return {
      loading: false,
      wrongLogin: false,
      menuAnimationSeconds: 0.4,
      foundLiveEvents: true,
    };
  },
  watch: {},
  computed: {
    isAModalOpen() {
      return this.$store.getters.isAModalOpen;
    },
    modalStatus() {
      return this.$store.getters.modalStatus;
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    showMobileMenu() {
      return this.$store.getters.showMobileMenu;
    },
    showModal() {
      return this.$store.getters.showItems;
    },
    closeAllModals: function () {
      return this.$store.getters.escapePressed;
    },
    showScorecardBanner() {
      return this.$store.getters.userScorecards.length > 0;
    },
    showMarshallBanner() {
      return this.$store.getters.marshallGroups.length > 0;
    },
  },
  methods: {
    loginUser(info) {
      this.wrongLogin = false;

      this.$axios({
        data: {
          query: `
          mutation {
            CreateAuthToken(email:"${info.email}",password:"${info.password}"){
              userId
              token
              expireAt
            }
          }
          `,
        },
      }).then((result) => {
        if (result.data.data.CreateAuthToken == null) {
          this.wrongLogin = true;
        } else {
          var tokenObject = result.data.data.CreateAuthToken;
          tokenObject.token = `Bearer ${result.data.data.CreateAuthToken.token}`;

          this.$axios({
            headers: { Authorization: tokenObject.token },
            data: {
              query: `
                query {
                  me
                  {
                    id
                    firstName
                    lastName
                  }
                }
              `,
            },
          }).then((result) => {
            tokenObject.firstName = result.data.data.me.firstName;
            tokenObject.lastName = result.data.data.me.lastName;

            this.$store.dispatch("setSessionInfo", tokenObject);

            localStorage.setItem("userObject", JSON.stringify(tokenObject));

            this.$store.dispatch("setUserInfo", result.data.data.me);
            this.$store.dispatch("checkForOpenGroups", true);
            this.$store.dispatch("hideAllModals");
          });
        }
      });
    },
    showLoginModal() {
      this.$store.dispatch("toggleLoginModal", true);
    },
    toggleMobileMenu() {
      if (this.showMobileMenu || this.isAModalOpen) {
        this.$store.dispatch("toggleMobileMenu", false);
      } else {
        this.$store.dispatch("toggleMobileMenu", true);
      }

      this.$store.dispatch("hideAllModals");
    },
    closeModals() {
      this.$store.dispatch("hideAllModals");
    },
    swapModals(to) {
      if (to == "login") {
        this.$store.dispatch("hideAllModals");
        this.$store.dispatch("toggleMobileMenu", false);
        this.$store.dispatch("toggleLoginModal", true);
      } else if (to == "register") {
        this.$store.dispatch("hideAllModals");
        this.$store.dispatch("toggleMobileMenu", false);
        this.$store.dispatch("toggleRegisterModal", true);
      } else if (to == "password") {
        this.$store.dispatch("hideAllModals");
        this.$store.dispatch("toggleMobileMenu", false);
        this.$store.dispatch("toggleForgotPasswordModal", true);
      }
    },
  },
};
</script>

<style lang="scss">
/* buttonStylesWithHoverEffect(buttonContainerHeight, buttonHeight); */
@include buttonStylesWithHoverEffect(44px, 40px);

.notifications-wrapper {
  position: absolute;
  right: 15px;
  top: 54px;
  width: calc(100% - 30px);
  overflow: hidden;
  max-height: 0;
  transition: all ease 0.5s;

  &.expanded {
    max-height: 500px;
    transition: all ease 0.5s;
  }
}

a {
  cursor: pointer;
}

#nav-icon3 {
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $midnight;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 8px;
}

#nav-icon3 span:nth-child(4) {
  top: 16px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navbar-toggler {
  padding: 0;
  height: 24px;
}

.active-scorecard-mobile {
  background: $grass;
  @include Gilroy-Bold;
  font-size: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  color: white;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}

.marshall-scorecard-mobile {
  background: $gold;
  @include Gilroy-Bold;
  font-size: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  color: white;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}
.dropdown-menu {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 0;
  left: 10px;
  top: calc(100%);
  border: 0px solid black;
  min-width: 12rem;
  .dropdown-menuItem-icon {
    margin-right: 1rem;
    width: 48px;
    height: 48px;
  }
  .dropdown-item:nth-child(1) {
    padding-top: 12px;
  }
  .dropdown-item:nth-last-child(1) {
    padding-bottom: 12px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.dropdown-toggle {
  display: flex;
  align-items: center;
  .dropdownArrow {
    width: 12px;
    height: 12px;
    transition: transform 0.5s;
    margin-left: 6px;
  }
}
/* .dropdown .dropdownArrow.active {
  transform: rotateX(180deg);
} */
.dropdown-toggle::after {
  display: none;
}

a:hover {
  .dropdown-itemTjingCustomSubtitle,
  .dropdown-itemTjingCustomIcon {
    color: initial !important;
  }
}
.dropdown-itemTjingCustomSubtitle {
  font-size: 14px;
  margin-bottom: 0px;
}
.dropdown-item {
  line-height: 26px;
  @include Gilroy-Medium;
  font-size: 16px;
  display: flex;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  &:hover {
    background-color: $mist;
  }
}
.dropdown-itemTjingCustom {
  display: inline-block;
  @include Gilroy-Bold;
  p {
    margin: 0;
  }
}
.nav-link {
  @include Gilroy-Bold;
  font-size: 16px;
  color: $midnight;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.navbar {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  background-color: #fff;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  z-index: 10;
  transition: all ease 0.3s;
  border-bottom: 1px solid transparent;
  .liveEvent {
    position: relative;
    a {
      display: flex;
      align-items: center;
    }
  }
  .navbar-brand {
    margin: 0;
    padding: 0;
  }

  .notifications-badge {
    margin-left: auto;
    .badge {
      background-color: $watermelon;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include Gilroy-Bold;
      color: white;
      font-size: 12px;
      margin-right: 20px;
    }
  }
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 42px;
  border-radius: 50%;
  background-color: #b40068 !important;
  letter-spacing: 1px;
  margin: 0;
  color: white !important;
  @include Gilroy-Bold;
  font-weight: bold !important;
  transition: all 0.3s;
  &.circle:hover {
    background-color: #a00056 !important;
  }
}

.dropdown-menu {
  &.profile-menu {
    top: calc(100%);
    left: -75px;
    min-width: initial;
  }

  &.sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
  }
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

/* .dropdown:hover .dropdown-menu {
  display: block;
} */

.nav-tabs .dropdown-menu,
.nav-pills .dropdown-menu,
.navbar .dropdown-menu {
  margin-top: 0;
}

.navbar .sub-menu:before {
  border-bottom: 7px solid transparent;
  border-left: none;
  border-right: 7px solid rgba(0, 0, 0, 0.2);
  border-top: 7px solid transparent;
  left: -7px;
  top: 10px;
}

.navbar .sub-menu:after {
  border-top: 6px solid transparent;
  border-left: none;
  border-right: 6px solid #fff;
  border-bottom: 6px solid transparent;
  left: 10px;
  top: 11px;
  left: -6px;
}
a {
  &:active {
    color: $midnight;
  }
}
.loadingbar {
  z-index: 999;
  height: 3px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &.active:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 3px;
    background-color: $elephant;
    animation: loading 1.5s linear infinite;
  }
}
@keyframes loading {
  from {
    left: -200px;
    width: 10%;
  }
  50% {
    width: 30%;
  }
  60% {
    width: 50%;
  }
  90% {
    width: 50%;
  }
  to {
    left: 80%;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .navbarWrapper {
    border-bottom: 1px solid #ebeaeb;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .navbar {
    height: 80px !important;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding-left: 0rem;
    padding-right: 1.5rem;
  }
  .dropdown-menuWithAnimation {
    display: none;
    &.show {
      display: block;
    }
    /* transform-origin: -40% 40%; */
    /* &.visible {
      animation: scale-display 0.3s;
      display: block !important;
    }
    &.hidden {
      animation: scale-display--reversed 0.4s;
      animation-fill-mode: forwards;
      display: block !important;
    } */
  }
}
@keyframes scale-display {
  0% {
    opacity: 0;
    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}
@keyframes scale-display--reversed {
  0% {
    display: block !important;
    opacity: 1;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  99% {
    display: block !important;
    opacity: 0;
    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
  }
  100% {
    display: none !important;
    opacity: 0;
    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
  }
}
@media (min-width: 1200px) {
  .notifications-wrapper {
    position: absolute;
    right: 15px;
    top: 60px;
    width: 450px;
    overflow: hidden;
    max-height: 0;
    transition: all ease 0.5s;

    &.expanded {
      max-height: 500px;
      overflow: inherit;
    }
  }

  button {
    &.signup-free {
      height: 34px !important;
      font-size: 16px;
      @include Gilroy-Bold;
    }
  }

  .active-scorecard {
    background: $grass;
    @include Gilroy-Bold;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    color: white;
    padding: 15px;
    cursor: pointer;
    border-radius: 6px;
    margin-left: 18px;
  }

  .marshall-scorecard {
    background: $gold;
    @include Gilroy-Bold;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    color: white;
    padding: 15px;
    cursor: pointer;
    border-radius: 6px;
  }

  .dropdown-menu {
    top: calc(100% + 21px);
    border: 0px solid black;
  }

  .nav-item {
    display: flex;
    align-items: center;
    .nav-link {
      @include Gilroy-Medium;
      font-size: 16px;
      color: $midnight;
      &:hover {
        color: $elephant !important;
      }
    }
    &.show {
      /* .nav-link {
        color: $elephant;
      } */
      .dropdownArrow {
        transform: rotateX(180deg);
      }
    }
  }

  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }

  @mixin startOfAnimation {
    transform: translateY(-1rem);
    opacity: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @mixin midOfAnimation {
    transform: translateY(0.5rem);
    opacity: 0.5;
    margin-bottom: 7px;
  }

  @mixin endOfAnimation($paddingTop, $paddingBottom) {
    transform: translateY(0rem);
    opacity: 1;
    padding-top: $paddingTop;
    padding-bottom: $paddingBottom;
    margin-bottom: 0px;
  }

  @keyframes slideIn {
    0% {
      @include startOfAnimation;
    }
    50% {
      @include midOfAnimation;
    }
    100% {
      @include endOfAnimation(10px, 10px);
    }
    0% {
      @include startOfAnimation;
    }
  }

  @-webkit-keyframes slideIn {
    0% {
      @include startOfAnimation;
    }
    50% {
      @include midOfAnimation;
    }
    100% {
      @include endOfAnimation(10px, 10px);
    }
    0% {
      @include startOfAnimation;
    }
  }

  @keyframes slideIn-firstDropdownItem {
    0% {
      @include startOfAnimation;
    }
    50% {
      @include midOfAnimation;
    }
    100% {
      @include endOfAnimation(12px, 10px);
    }
    0% {
      @include startOfAnimation;
    }
  }

  @-webkit-keyframes slideIn-firstDropdownItem {
    0% {
      @include startOfAnimation;
    }
    50% {
      @include midOfAnimation;
    }
    100% {
      @include endOfAnimation(12px, 10px);
    }
    0% {
      @include startOfAnimation;
    }
  }

  @keyframes slideIn-lastDropdownItem {
    0% {
      @include startOfAnimation;
    }
    50% {
      @include midOfAnimation;
    }
    100% {
      @include endOfAnimation(10px, 12px);
    }
    0% {
      @include startOfAnimation;
    }
  }

  @-webkit-keyframes slideIn-lastDropdownItem {
    0% {
      @include startOfAnimation;
    }
    50% {
      @include midOfAnimation;
    }
    100% {
      @include endOfAnimation(10px, 12px);
    }
    0% {
      @include startOfAnimation;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      -webkit-opacity: 0;
    }
    100% {
      -webkit-opacity: 1;
    }
    0% {
      -webkit-opacity: 0;
    }
  }

  .slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
  }
  .slideIn:nth-child(1) {
    -webkit-animation-name: slideIn-firstDropdownItem;
    animation-name: slideIn-firstDropdownItem;
  }
  .slideIn:nth-last-child(1) {
    -webkit-animation-name: slideIn-lastDropdownItem;
    animation-name: slideIn-lastDropdownItem;
  }
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .navbar {
    background-color: white;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 0px;
    z-index: 10;
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 42px;
    border-radius: 50%;
    background-color: $sky;
    letter-spacing: 1px;
    margin: 0;
    color: white;

    &.circle:hover {
      color: white !important;
    }
  }

  .dropdown-menu {
    &.profile-menu {
      top: calc(100%);
      left: -75px;
      min-width: initial;
    }

    &.sub-menu {
      left: 100%;
      position: absolute;
      top: 0;
      visibility: hidden;
      margin-top: -1px;
    }
  }

  .dropdown-menu li:hover .sub-menu {
    visibility: visible;
  }

  /* .dropdown:hover .dropdown-menu {
    display: block;
  } */

  .nav-tabs .dropdown-menu,
  .nav-pills .dropdown-menu,
  .navbar .dropdown-menu {
    margin-top: 0;
  }

  .navbar .sub-menu:before {
    border-bottom: 7px solid transparent;
    border-left: none;
    border-right: 7px solid rgba(0, 0, 0, 0.2);
    border-top: 7px solid transparent;
    left: -7px;
    top: 10px;
  }

  .navbar .sub-menu:after {
    border-top: 6px solid transparent;
    border-left: none;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent;
    left: 10px;
    top: 11px;
    left: -6px;
  }
  a {
    &:active {
      color: $midnight;
    }
  }
  .loadingbar {
    z-index: 999;
    height: 3px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    &.active:before {
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 3px;
      background-color: $elephant;
      animation: loading 1.5s linear infinite;
    }
  }
  @keyframes loading {
    from {
      left: -200px;
      width: 10%;
    }
    50% {
      width: 30%;
    }
    60% {
      width: 50%;
    }
    90% {
      width: 50%;
    }
    to {
      left: 80%;
    }
  }
  .navbar {
    position: relative;
  }

  .loadingbar {
    z-index: 9999;
    height: 4px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    &.active:before {
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: $elephant;
      animation: loading 2s linear infinite;
    }
  }

  @keyframes loading {
    from {
      left: -200px;
      width: 10%;
    }
    50% {
      width: 30%;
    }
    60% {
      width: 50%;
    }
    70% {
      width: 20%;
    }
    80% {
      left: 100%;
    }
    95% {
      left: 120%;
    }
    to {
      left: 100%;
    }
  }
}
</style>
