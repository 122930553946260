import "./assets/WebFonts.css";
import "./styles/global-normalize.scss";
import "./styles/global-styles.scss";
import "./styles/global-graphs.scss";
import "vue-resize/dist/vue-resize.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap";
import "./registerServiceWorker";
import moment from "moment";
import Vue from "vue";
import App from "./App.vue";
import store from "@/stores/store";
import router from "./router";
import VueResize from "vue-resize";
import vbclass from "vue-body-class";
import { VueHammer } from "vue2-hammer";
import Meta from "vue-meta";
import NavigationMenu from "./components/NavigationMenu";
import Footer from "./components/Footer";
import axios from "axios";
import PrismicVue from "@prismicio/vue";
import linkResolver from "@/link-resolver.js";
import SweetModal from "sweet-modal-vue/src/plugin.js";
import LoadScript from "vue-plugin-load-script";
import TjingToolTip from "@/components/TjingToolTip";
import VueScrollTo from "vue-scrollto";

Vue.prototype.moment = moment;

Vue.component("NavigationMenu", NavigationMenu);
Vue.component("Footer", Footer);
Vue.component("TjingToolTip", TjingToolTip);

// We can't use axios 0.25 without adding a url and baseurl for all requests, hopefully this
// is changed in future versions in axios as this does not work well with a single graph endpoint, verbose
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.method = "post";
axios.defaults.timeout = 15000;
axios.defaults.headers.post = {
	"Cache-Control": "no-cache",
	"Content-Type": "application/json",
};
Vue.prototype.$axios = axios;

VueHammer.config.swipe = {
	threshold: 75,
	direction: "horizontal",
};
VueHammer.config.press = {
	time: 250,
	threshold: 75,
};
Vue.use(VueHammer);

Vue.use(SweetModal);
Vue.use(VueScrollTo);
Vue.use(VueResize);
Vue.use(Meta);
Vue.use(LoadScript);
Vue.use(vbclass, router);
Vue.use(PrismicVue, {
	endpoint: "https://tjing.cdn.prismic.io/api/v2",
	linkResolver,
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	async beforeMount() {
		if (localStorage.getItem("userObject")) {
			let tokenObject = JSON.parse(localStorage.getItem("userObject"));

			this.$store.dispatch("setSessionInfo", tokenObject);

			if (moment(tokenObject.expireAt).isBefore()) {
				localStorage.removeItem("userObject");
			}

			this.$store.dispatch("setUserInfo", tokenObject);
			this.$store.dispatch("authenticationState", true);
			this.$store.dispatch("checkForOpenGroups", true);
			this.$store.dispatch("updateUserEventInformation");
		}

		this.$router.beforeEach((to, from, next) => {
			if (this.$store.getters.isUserLoggedIn) {
				this.$store.dispatch("checkForOpenGroups", false);
			}
			next();
		});

		document.onkeydown = (evt) => {
			if (evt.code == "Escape") {
				this.$store.dispatch("escapePressed");
			}
		};
	},
	render: (h) => h(App),
}).$mount("#app");
