import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/stores/store";

Vue.use(Router);

export default new Router({
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	mode: "history",
	routes: [
		{
			path: "*",
			name: "404",
			component: () => import("@/views/404.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "404 - Not Found";
				next();
			},
		},
		{
			path: "/",
			name: "home",
			meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
			component: Home,
			beforeEnter: (to, from, next) => {
				document.title = "Tjing";

				next();
			},
		},
		{
			path: "/prismic",
			name: "prismic",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/prismic/Prismic.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Prismic Content";
				next();
			},
		},
		{
			path: "/about",
			name: "about",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/views/About.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - About";
				next();
			},
		},
		{
			path: "/faq",
			name: "faq",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/prismic/FAQ.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - FAQ";
				next();
			},
		},
		{
			path: "/hasard",
			name: "hasard",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/gadgets/Hasard.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Hasard";
				next();
			},
		},
		{
			path: "/measure",
			name: "measure",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/gadgets/ThrowMeassuring.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Measure";
				next();
			},
		},
		{
			path: "/contact",
			name: "contact",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/views/Contact.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Contact";
				next();
			},
		},
		{
			path: "/career",
			name: "career",
			meta: { bodyClass: "noTopMarginFooter noMinHeight" },
			component: () => import("@/pages/OpenPositions.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Positions";
				next();
			},
		},
		{
			path: "/typo",
			name: "typo",
			meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
			component: () => import("@/pages/Typo.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Contact";
				next();
			},
		},
		{
			path: "/dashboard",
			name: "dashboard",
			component: () => import("@/views/UserDashboard.vue"),
			redirect: { name: "dashboard-activity" },
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Dashboard";
				next();
			},
			children: [
				{
					path: "activity",
					name: "dashboard-activity",
					component: () => import("@/pages/DashboardActivity.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Activity";
						next();
					},
				},
				{
					path: "progress",
					name: "dashboard-progress",
					component: () => import("@/pages/DashboardProgress.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Progress";
						next();
					},
				},
				{
					path: "starred",
					name: "dashboard-starred",
					component: () => import("@/pages/DashboardFavourites.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Starred";
						next();
					},
				},
			],
		},
		{
			path: "/whytjing",
			name: "whytjing",
			meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
			component: () => import("@/views/WhyTjing.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Why Tjing";
				next();
			},
		},
		{
			path: "/blog",
			name: "blog",
			component: () => import("@/views/Blog.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Blog";
				next();
			},
		},
		{
			path: "/gratitude",
			name: "gratitude",
			component: () => import("@/views/Gratitude.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Gratitude Wall";
				next();
			},
		},
		{
			//legacy path
			path: "/profile",
			redirect: { name: "profile", params: { view: "basics" } },
		},
		{
			path: "/profile/:view",
			name: "profile",
			component: () => import("@/views/Profile.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Profile";
				next();
			},
		},
		{
			path: "/login/:source",
			name: "login",
			meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
			component: () => import("@/components/TheLoginPage.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Login";
				next();
			},
		},
		{
			path: "/register",
			name: "register",
			meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
			component: () => import("@/components/TheCreateAccountPage.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Get Started";
				next();
			},
		},
		{
			path: "/legal",
			name: "legal",
			component: () => import("@/views/Legal.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Legal";
				next();
			},
			children: [
				{
					path: "terms",
					name: "terms",
					component: () => import("@/components/TermsOfService.vue"),
				},
				{
					path: "privacy",
					name: "privacy",
					component: () => import("@/components/PrivacyPolicy.vue"),
				},
			],
		},
		{
			path: "/forgotpassword",
			name: "forgotpassword",
			meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
			component: () => import("@/components/TheForgotPasswordPage.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Reset Password";
				next();
			},
		},
		{
			path: "/verifyemail",
			name: "verifyemail",
			component: () => import("@/components/TheEmailVerifier.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Verify Email";
				next();
			},
		},
		{
			path: "/scorecard/",
			name: "scorecard",
			component: () => import("@/components/scorecard/TheScorecard.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Scorecard";
				store.dispatch("setMainNavState", false);
				next();
			},
			children: [
				{
					path: "choose",
					name: "scorecard-picker",
					component: () => import("@/components/scorecard/TheScorecardPicker.vue"),
				},
				{
					path: ":groupId/marker",
					name: "marker",
					meta: { bodyClass: "midnightbg" },
					component: () => import("@/components/scorecard/TheMarker.vue"),
				},
				{
					path: ":groupId/verifier",
					name: "verifier",
					meta: { bodyClass: "midnightbg" },
					component: () => import("@/components/scorecard/TheVerifier.vue"),
				},
				{
					path: ":groupId/stattracker",
					name: "stattracker",
					meta: { bodyClass: "midnightbg" },
					component: () => import("@/components/scorecard/ThePersonalStatTracker.vue"),
				},
				{
					path: ":groupId/tracker",
					name: "tracker",
					component: () => import("@/components/TheTracker.vue"),
				},
			],
		},
		{
			path: "/courses",
			name: "coursedirectory",
			props: true,
			meta: { bodyClass: "noTopMarginFooter " },
			component: () => import("@/components/TjingAdminCourseManager.vue"),
		},
		{
			path: "/events",
			name: "events",
			meta: { bodyClass: "full noTopMarginBody " },
			component: () => import("@/views/Events.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Events";
				next();
			},
		},
		{
			//legacy path
			path: "/events/upcoming",
			redirect: { name: "events" },
		},
		{
			path: "/eventsclassic/:tab",
			name: "specifiedevents",
			props: true,
			component: () => import("@/views/Events.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Events";
				next();
			},
		},
		{
			path: "/manage",
			name: "manage",
			meta: { bodyClass: "mist-background" },
			component: () => import("@/views/OrganizerManagerOverview.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Manager";
				next();
			},
		},
		{
			path: "/manage/tour/:tourId",
			meta: { bodyClass: "mist-background" },
			component: () => import("@/views/OrganizerTourOverview.vue"),
			children: [
				{
					path: "/manage/tour/:tourId",
					name: "tour-overview",
					meta: { bodyClass: "mist-background" },
					component: () => import("@/components/ManagerTourEvents.vue"),
				},
				{
					path: "settings",
					name: "tour-overview-settings",
					meta: { bodyClass: "mist-background" },
					component: () => import("@/components/TourSettings.vue"),
				},
				{
					path: "utility",
					name: "tour-overview-utility",
					meta: { bodyClass: "mist-background" },
					component: () => import("@/pages/TourUtilities.vue"),
				},
				{
					path: "addevent",
					name: "tour-overview-add-event",
					meta: { bodyClass: "mist-background" },
					component: () => import("@/components/AddNewEvent.vue"),
				},
				{
					path: "divisions",
					name: "tour-overview-divisions",
					meta: { bodyClass: "mist-background" },
					component: () => import("@/components/TourDivisions.vue"),
				},
			],
		},

		{
			path: "/courses",
			name: "coursemanager",
			props: true,
			component: () => import("@/components/CourseManager.vue"),
		},
		{
			path: "/manage/courses/",
			name: "coursemanagerbeta",
			props: true,
			meta: { bodyClass: "noTopMarginFooter" },
			component: () => import("@/components/TjingAdminCourseManager.vue"),
		},
		{
			path: "/managedevent",
			name: "eventmanager",
			meta: { bodyClass: "mist-background" },
			component: () => import("@/components/EventManager.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Manager";
				next();
			},
			children: [
				{
					path: "/manage/:eventId/overview",
					name: "event-overview",
					component: () => import("@/components/TheEventTimeline.vue"),
				},
				{
					path: "/manage/:eventId/players",
					name: "event-players",
					component: () => import("@/components/EventPlayers.vue"),
				},
				{
					path: "/manage/:eventId/edit-results",
					name: "event-edit-results",
					component: () => import("@/components/EventPlayerScores.vue"),
				},
				{
					path: "/manage/:eventId/contact",
					name: "event-contact",
					component: () => import("@/components/EventContactDetails.vue"),
				},
				{
					path: "/manage/:eventId/messages",
					name: "event-messages",
					component: () => import("@/components/EventMessages.vue"),
				},
				{
					path: "/manage/:eventId/settings",
					name: "event-settings",
					component: () => import("@/components/EventSettings.vue"),
				},
				{
					path: "/manage/:eventId/addevent/:tourId",
					name: "one-off-settings-add-event",
					meta: { bodyClass: "mist-background" },
					component: () => import("@/components/AddNewEvent.vue"),
				},
				{
					path: "/manage/:eventId/oneoffsettings",
					name: "one-off-event-settings",
					component: () => import("@/components/OneOffEventSettings.vue"),
				},
				{
					path: "/manage/:eventId/rounds",
					name: "event-rounds",
					component: () => import("@/components/EventRoundsNavigator.vue"),
				},
				{
					path: "/manage/:eventId/groupeditor/:roundId/",
					name: "event-groups-round",
					component: () => import("@/components/GroupEditor.vue"),
				},
			],
		},

		{
			path: "/builder/",
			name: "",
			component: () => import("@/views/Builder.vue"),
			meta: { bodyClass: "mist-background" },
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Builder";
				next();
			},
			children: [
				{
					path: "",
					name: "builder",
					component: () => import("@/components/builder/Home.vue"),
				},
				{
					path: "oneoff",
					name: "singleevent",
					component: () => import("@/components/builder/SingleEvent.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Single event builder";
						next();
					},
					children: [
						{
							path: "mandatory",
							name: "singleeventmandatory",
							component: () => import("@/components/builder/SingleEventMandatory.vue"),
						},
						{
							path: "features",
							name: "singleeventfeatures",
							component: () => import("@/components/builder/SingleEventFeatures.vue"),
						},
						{
							path: "settings",
							name: "singleeventsettings",
							component: () => import("@/components/builder/SingleEventSettings.vue"),
						},
						{
							path: "optional",
							name: "singleeventoptional",
							component: () => import("@/components/builder/SingleEventOptional.vue"),
						},
					],
				},
				{
					path: "tournament",
					name: "tournament",
					component: () => import("@/components/builder/Tournament.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Tournament builder";
						next();
					},
					children: [
						{
							path: "details",
							name: "tournamentdetails",
							component: () => import("@/components/builder/TournamentDetails.vue"),
						},
						{
							path: "features",
							name: "tournamentfeatures",
							component: () => import("@/components/builder/TournamentFeatures.vue"),
						},
						{
							path: "events",
							name: "tournamenteventdetails",
							component: () => import("@/components/builder/TournamentEventDetails.vue"),
						},
					],
				},
				{
					path: "league",
					name: "weeklytour",
					component: () => import("@/components/builder/League.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - League builder";
						next();
					},
					children: [
						{
							path: "mandatory",
							name: "leaguesmandatory",
							component: () => import("@/components/builder/LeaguesMandatory.vue"),
						},
						{
							path: "settings",
							name: "leaguessettings",
							component: () => import("@/components/builder/LeaguesSettings.vue"),
						},
						{
							path: "features",
							name: "leaguesfeatures",
							component: () => import("@/components/builder/LeaguesFeatures.vue"),
						},
						{
							path: "optional",
							name: "leaguesoptional",
							component: () => import("@/components/builder/LeaguesOptional.vue"),
						},
					],
				},
			],
		},
		{
			path: "/tour/",
			name: "tournamentoverview",
			meta: { bodyClass: "noTopMarginBody" },
			component: () => import("@/views/PublicTourOverview.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Tour overview";
				next();
			},
			children: [
				{
					path: ":tourId/standings",
					name: "tournamentoverview-standings",
					component: () => import("@/pages/PublicTourStandings.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Tour overview";
						next();
					},
				},
				{
					path: ":tourId/standings-all",
					name: "tournamentoverview-complete-standings",
					component: () => import("@/pages/PublicCompleteTourStandings.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Tour overview";
						next();
					},
				},
				{
					path: ":tourId/events",
					name: "tournamentoverview-events",
					component: () => import("@/pages/PublicTourEventsList.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Tour overview";
						next();
					},
				},

				{
					path: ":tourId/stats",
					name: "tournamentoverview-stats",
					component: () => import("@/pages/PublicTourStats.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Tour overview";
						next();
					},
				},
			],
		},
		{
			path: "/event/:eventId/round",
			name: "public-event-round",
			meta: { bodyClass: "noTopMarginBody" },
			component: () => import("@/views/PublicEventRound.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Event rounds";
				next();
			},
			children: [
				{
					path: ":poolId/results",
					name: "public-event-pool-results",
					component: () => import("@/pages/PublicEventPoolResults.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event rounds";
						next();
					},
				},
				{
					path: ":poolId/groups",
					name: "public-event-pool-groups",
					component: () => import("@/pages/PublicEventPoolGroups.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event rounds";
						next();
					},
				},
				{
					path: ":poolId/stats",
					name: "public-event-pool-stats",
					component: () => import("@/pages/PublicEventPoolStats.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event rounds";
						next();
					},
				},
				{
					path: ":poolId/compare",
					name: "public-event-pool-compare",
					component: () => import("@/pages/PublicEventPoolCompare.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event rounds";
						next();
					},
				},
			],
		},
		{
			path: "/event/:eventId/registration",
			name: "public-event-registration",
			component: () => import("@/views/PublicEventRegistration.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Event registration";
				next();
			},
			children: [
				{
					path: "/event/:eventId/groupregistration",
					name: "public-event-group-registration",
					meta: { bodyClass: "noTopMarginBody" },
					component: () => import("@/components/GroupRegistration.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event group registration";
						next();
					},
				},
				{
					path: "/event/:eventId/checkout/:groupId?",
					name: "public-event-checkout",
					meta: { bodyClass: "noTopMarginBody" },
					component: () => import("@/pages/Checkout.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Checkout";
						next();
					},
				},
			],
		},
		{
			path: "/event/",
			name: "event",
			props: true,
			meta: { bodyClass: "noTopMarginBody" },
			component: () => import("@/views/PublicEvent.vue"),
			beforeEnter: (to, from, next) => {
				document.title = "Tjing - Event";
				next();
			},
			children: [
				{
					path: ":eventId",
					name: "public-event",
					redirect: { name: "public-event-results" },
				},
				{
					path: ":eventId/results",
					name: "public-event-results",
					component: () => import("@/pages/PublicEventStandings.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event results";
						next();
					},
				},
				{
					path: ":eventId/rounds",
					name: "public-event-rounds",
					meta: { bodyClass: "noTopMarginBody" },
					component: () => import("@/pages/PublicEventRounds.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event rounds";
						next();
					},
				},
				{
					//legacy path
					path: ":eventId/playerlist",
					redirect: { name: "public-event-players" },
				},
				{
					path: ":eventId/players",
					name: "public-event-players",
					component: () => import("@/pages/PublicPlayersList.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event players";
						next();
					},
				},
				{
					path: ":eventId/details",
					name: "public-event-details",
					component: () => import("@/pages/PublicEventDetails.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event details";
						next();
					},
				},
				{
					path: ":eventId/messages",
					name: "public-event-messages",
					component: () => import("@/pages/PublicEventMessages.vue"),
					beforeEnter: (to, from, next) => {
						document.title = "Tjing - Event messages";
						next();
					},
				},
			],
		},
		{
			// legacypath
			path: "/event/:eventId/livepool/:poolId",
			name: "livepool",
			props: true,
			meta: { bodyClass: "full-with-padding" },
			redirect: { name: "public-event-pool-results" },
		},
	],
});
