<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.414"
    height="13.415"
    viewBox="0 0 13.414 13.415"
    :class="color"
  >
    <g transform="translate(0.707 0.707)">
      <line class="a" x2="16.971" transform="translate(0 12) rotate(-45)" />
      <line class="a" x2="16.971" transform="translate(0 0.001) rotate(45)" />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["color"],
  name: "Crossmark",
};
</script>

<style lang="scss" scoped>
.twilight {
  .a {
    fill: none;
    stroke: #b40068;
    stroke-width: 2px;
  }
}
.ocean {
  .a {
    fill: none;
    stroke: #05b5bc;
    stroke-width: 2px;
  }
}

.a {
  fill: none;
  stroke: #2d323c;
  stroke-width: 2px;
}
</style>