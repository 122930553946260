<template>
  <section class="signUpCTA">
    <h1>It's easy to get started</h1>
    <h5>Getting better is up to you but we think we can help.</h5>
    <div class="buttonSection">
      <div class="buttonContainer">
        <router-link :to="{ name: 'register' }">
          <button class="shampoo reversed">Sign up free</button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SignUpCTA",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@include buttonStylesWithHoverEffect;
h1 {
  @include Gilroy-Bold;
  font-size: 64px;
}
h1,
h5 {
  color: #fff !important;
}
h5 {
  font-size: 24px;
  line-height: 40px;
  @include Gilroy-Regular;
}
.shampoo {
  border: none !important;
}
.signUpCTA {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: $shampoo;
  text-align: center;
  padding: 50px 0px 90px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  * {
    display: block;
    margin: 0 auto;
    margin-top: 22px;
    max-width: 95%;
  }
  button {
    margin-top: 62px;
  }
}
@media (max-width: 767px) {
  .signUpCTA {
    padding: {
      top: 50px;
      bottom: 50px;
    }
    * {
      margin-top: 0px;
    }
    h1 {
      margin-bottom: 22px;
      font-size: 32px;
    }
    h5 {
      font-size: 16px;
      line-height: 24px;
    }
    button {
      margin-top: 22px;
      width: 100%;
    }
  }
}
</style>
