<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.412"
    height="14.828"
    viewBox="0 0 14.412 14.828"
    :class="color"
  >
    <g transform="translate(-330.002 -1291.586)">
      <path
        class="a"
        d="M1193.787,2693.444h12"
        transform="translate(-862.785 -1394.444)"
      />
      <line class="a" x1="6" y1="6" transform="translate(337 1293)" />
      <line class="a" x1="6" y2="6" transform="translate(337 1299)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Arrow",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.twilight {
  .a {
    fill: none;
    stroke: #b40068;
    stroke-linecap: round;
    stroke-width: 2px;
  }
}
.a {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 2px;
}
</style>