import Vue from "vue";
import Vuex from "vuex";
import colors from "./ColorModule";
import modals from "./Modals";
import builder from "./Builder";
import temporaryCloseableElements from "./TemporaryCloseableElements";
import router from "../router";
import axios from "axios";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		colors,
		modals,
		builder,
		temporaryCloseableElements,
	},
	state: {
		returnToRoute: null,
		messages: {
			show: false,
			message: "",
			timer: 1500,
			type: "success",
			style: "midnight",
		},
		currencyMinimumChargeAmount: {
			SEK: 3,
			DKK: 2.5,
			NOK: 3,
			AUD: 0.5,
			GBP: 0.3,
			EUR: 0.5,
			USD: 0.5,
		},
		managedEvents: {
			data: null,
			loaded: false,
		},
		currentlyManagedEvent: {
			data: null,
			myCourses: null,
			loaded: false,
		},
		eventMessages: [],
		eventInformation: {
			showEventInfo: false,
			showInfo: false,
			details: {},
		},
		poolConversion: ["A", "B", "C", "D", "E", "F", "G"],
		loginProcess: {
			invalidEmail: false,
			invalidPassword: false,
		},
		tourDivisions: [],
		user: {
			watchedEvents: [],
			watchedEventsLoaded: false,
			tourPasses: [],
			registrations: [],
			favouriteEvents: [],
			isAuthenticated: false,
			isVerified: false,
			emailVerifyInformation: {
				showBanner: false,
				closedDateTime: {},
			},
			eventRegistrations: [],
			sessionInfo: {
				expireAt: "",
				token: "",
				userId: "",
			},
			isMarshall: [],
			openPools: [],
			scorecards: [],
			firstName: "",
			lastName: "",
			email: "",
		},
		utility: {
			showTopNav: true,
			showFooter: true,
			showMobileMenu: false,
			deviceWidth: 0,
			escapePressed: false,
			loading: false,
			loadingTimeStamp: moment().valueOf(),
			onTjingMobile: navigator.userAgent.includes("tjing-mobile-app"),
		},
	},
	mutations: {
		async loadFavouriteEvents(state) {
			try {
				const favouriteEvents = await axios({
					headers: { Authorization: state.user.sessionInfo.token },
					data: {
						query: `
				  {
					me{
					  favouriteEvents{
						id
						event{
						  id
						  name
						  status
						  isRegistrationOpen
						  registrationOpenAt
						  checkInStartsAt
						  checkInEndsAt
						  isVerifyMode
						  isResultSigningEnabled
						  startDate
						  endDate
						  pdgaType{
							name
							type
						  }
						  city{
							name
							country
						  }
						  rounds{
							id
							pools{
							  id
							  date
							}
						  }
						  tour{
							name
							type{
							  name
							  type
							}
						  }
						}
					  }
					}
				  }
				  `,
					},
				});

				state.user.favouriteEvents = favouriteEvents.data.data.me.favouriteEvents;
			} catch (err) {
				console.log(err);
			}
		},
		setEventMessages(state, eventMessages) {
			state.eventMessages = eventMessages;
		},
		setReturnToRouteData(state, routeInfo) {
			state.returnToRoute = routeInfo;
		},
		showMessage(state, messageObject) {
			state.messages.message = messageObject.message;
			state.messages.type = messageObject.type;
			state.messages.style = messageObject.style;
			state.messages.timer = messageObject.timer === undefined ? 4000 : messageObject.timer;
			state.messages.show = true;
		},

		hideMessage(state) {
			state.messages.show = false;
			state.messages.timer = 1500;
		},
		hideEmailVerificationBanner(state) {
			state.user.emailVerifyInformation.showBanner = false;
			state.user.emailVerifyInformation.closedDateTime = moment();
		},
		authenticationState(state, bool) {
			state.user.isAuthenticated = bool;
		},
		toggleMobileMenu(state, bool) {
			state.utility.showMobileMenu = bool;
		},
		setFooterState(state, bool) {
			state.utility.showFooter = bool;
		},
		setMainNavState(state, bool) {
			state.utility.showTopNav = bool;
		},
		showEventInfo(state, bool) {
			state.eventInformation.showEventInfo = bool;
		},
		showEventInfoBody(state, bool) {
			state.eventInformation.showInfo = bool;
		},
		setEventInfo(state, information) {
			state.eventInformation.details = information;
		},
		toggleLoading(state, bool) {
			let timer = moment().valueOf() - state.utility.loadingTimeStamp;

			if (bool == true) {
				state.utility.loadingTimeStamp = moment().valueOf();
				state.utility.loading = bool;

				setTimeout(() => {
					state.utility.loading = false;
				}, 3000);
			}

			if (timer < 1750 && bool == false) {
				setTimeout(() => {
					state.utility.loading = false;
				}, 1750 - timer);
			}
		},
		escapePressed(state) {
			state.utility.escapePressed = true;
			Vue.nextTick(function () {
				state.utility.escapePressed = false;
			});
		},
		deviceWidth(state) {
			state.utility.deviceWidth = window.innerWidth;
		},
		logout(state) {
			router.push({ path: "/" });

			localStorage.removeItem("userObject");
			state.user = {
				watchedEvents: [],
				watchedEventsLoaded: false,
				tourPasses: [],
				registrations: [],
				isAuthenticated: false,
				isVerified: false,
				emailVerifyInformation: {
					showBanner: false,
					closedDateTime: {},
				},
				eventRegistrations: [],
				sessionInfo: {
					expireAt: "",
					token: "",
					userId: "",
				},
				isMarshall: [],
				openPools: [],
				scorecards: [],
				firstName: "",
				lastName: "",
				email: "",
			};
		},
		setSessionInfo(state, sessionInfo) {
			state.user.sessionInfo = sessionInfo;
			state.utility.escapePressed = true;
			Vue.nextTick(function () {
				state.utility.escapePressed = false;
			});
		},
		setUserInfo(state, userInfo) {
			state.user.firstName = userInfo.firstName;
			state.user.lastName = userInfo.lastName;
			state.user.email = userInfo.email;
		},
		setTourDivisions(state, divisions) {
			state.tourDivisions = divisions;
		},
		setMarshalls(state, marshall) {
			state.user.isMarshall = marshall;
		},
		updateUserEventInformation(state) {
			axios({
				headers: { Authorization: state.user.sessionInfo.token },
				data: {
					query: `
          {
            me{
              favouriteEvents{
                id
                event{
                  id
                }
              }
              tourPasses{
                id
                payment{
                  id
                  isRefunded
                }
                division{
                  id
                }
                tour{
                  id
                }
              }
              registrations(first:75){
                id
                status
                division{
                  id
                }
                event{
                  id
                }
              }
            }
          }
          `,
				},
			})
				.then((result) => {
					state.user.tourPasses = result.data.data.me.tourPasses;
					state.user.registrations = result.data.data.me.registrations;
				})
				.catch(() => {});
		},
		removeWatchedEvent(state, event) {
			state.user.watchedEvents = state.user.watchedEvents.filter((watched) => {
				return watched != event;
			});
		},
		addWatchedEvent(state, event) {
			state.user.watchedEvents.push(event);
		},
		async fetchWatchedEvents(state) {
			let watchedEvents = await axios({
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				},
				method: "GET",
				url: process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + "getUsersWatchedEvents?userid=" + state.user.sessionInfo.userId,
			});

			if (watchedEvents.data.watchedEvents) {
				state.user.watchedEvents = watchedEvents.data.watchedEvents;
			}
			state.user.watchedEventsLoaded = true;
		},
		activeScorecards(state) {
			axios({
				headers: { Authorization: state.user.sessionInfo.token },
				data: {
					query: `
          {
            isAuthenticated
            me{
              isVerified
              marshalls(status:OPEN){
                id
                pool{
                  round{
                    event{
                      name
                      tour{
                        name
                      }
                    }
                  }
                }
              }
              groups(
                filter: {
                  operator: OR
                  query: [
                      { operator: AND, groupStatus: OPEN, poolStatus: OPEN },
                      { operator: AND, groupStatus: DONE, poolStatus: OPEN }
                  ]
                }
              ){
                id
                playerConnections{
          				player{
                    userId
                  }
                }
                marshall{
                  id
          				profile{
                    id
                  }
                }
              }
            }
          }
          `,
				},
			})
				.then((result) => {
					state.user.isAuthenticated = result.data.data.isAuthenticated;
					state.user.openPools = [];

					if (result.data.data.me.isVerified == false) {
						if (localStorage.getItem("dataFetchedTimestamps")) {
							let timeStamps = JSON.parse(localStorage.getItem("dataFetchedTimestamps"));

							if (timeStamps.closedDateTime) {
								if (moment(timeStamps.closedDateTime).add(7, "days").isBefore()) {
									state.user.emailVerifyInformation.showBanner = true;
								}
							} else {
								state.user.emailVerifyInformation.showBanner = true;
							}
						} else {
							state.user.emailVerifyInformation.showBanner = true;
						}
					}
					if (state.utility.onTjingMobile) {
						state.user.emailVerifyInformation.showBanner = false;
					}

					if (result.data.data.isAuthenticated) {
						state.user.scorecards = result.data.data.me.groups;
						for (var i = 0; i < result.data.data.me.marshalls.length; i++) {
							state.user.isMarshall.push(result.data.data.me.marshalls[i]);
						}
					}
				})
				.catch(() => {});
		},
		async loadManagedEventData(state, payload) {
			try {
				let eventData = await axios({
					headers: { Authorization: state.user.sessionInfo.token },
					data: {
						query: `
          {
            me{
              courses{
                type
                name
                id
                layouts{
                  id
                  name
                  latestVersion{
                    id
                  }
                }
              }
            }
          event(eventId:"${payload}"){
            tour{
              id
              name
              isPublished
              registrationMethod
              type{
                name
                type
              }
              events{
                id
              }
            }
            pdgaType{
              name
              type
            }
            id
            name
            status
            maxSpots
            groupSize
            playersCount
            checkInStartsAt
            checkInEndsAt
            startDate
            endDate
            registrationOpenAt
            isRegistrationOpen
            isResultSigningEnabled
            isVerifyMode
            playersCount
            registrations{
              id
              status
            }
            rounds{
              id
              status
              isDeletable
              pools{
                id
                position
                status
                date
                isDeletable
                areGroupsPublic
                startMethod
                groups{
                  id
                  status
                  position
                  startsAt
                  startHole{
                    id
                    number
                    name
                  }
                  marshall{
                    id
                    firstName
                    lastName
                  }
                  playerConnections{
                    hasSignedResult
                    id
                    position
                    groupId
                    player{
                      id
                      isWildcard
                      hasPaid
                      division{
                        id
                        type
                        name
                      }
                      dnf{
                        isDnf
                      }
                      dns{
                        isDns
                      }
                      user{
                        id
                        firstName
                        lastName
                        profile{
                          pdgaNumber
                          pdgaRating
                          club
                        }
                      }
                    }
                  }
                  id
                  position
                  status
                  }
                  layoutVersion{
                    id
                    holes{
                      par
                      id
                      name
                      number
                    }
                    layout{
                      name
                      type
                      latestVersion{
                        id
                        holes {
                          par
                          id
                          name
                          number
                        }
                      }
                      course{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          `,
					},
				});

				eventData.data.data.event.rounds.forEach((round) => {
					round.pools.forEach((pool) => (pool.createGroupsMethod = "RANDOM"));
				});

				state.currentlyManagedEvent.data = eventData.data.data.event;
				state.currentlyManagedEvent.myCourses = eventData.data.data.me.courses;
				state.currentlyManagedEvent.loaded = true;
			} catch (err) {
				console.log(err);
			}
		},
		async loadManagedEvents(state) {
			try {
				let eventsData = await axios({
					headers: { Authorization: state.user.sessionInfo.token },
					data: {
						query: `
            {
              me{
                administratorRoles{
                  tour{
                    type{
                      type
                      name
                    }
                    scoreBasedOnNrOfEvents
                    scoringType
                    status
                    isArchived
                    registrationMethod
                    divisions{
                      type
                      name
                      id
                    }
                    administrators{
                      userId
                      user{
                        firstName
                        lastName
                      }
                    }
                    nextEvent{
                      tour{
                        name
                      }
                      id
                      name
                      status
                      isRegistrationOpen
                      registrationOpenAt
                      startDate
                      rounds{
                        pools{
                          date
                          layoutVersion{
                            layout{
                              name
                              latestVersion{
                                holes{
                                  par
                                }
                              }
                            }
                          }
                        }
                      }
                      registrationsCount
                      playersCount
											registrations{
												id
												status
											}
                    }
                    id
                    name
                    isPublished
                    events{
                      tour{
                        name
                      }
                      id
                      name
                      status
                      isRegistrationOpen
                      registrationOpenAt
                      startDate
                      pdgaType{
                        name
                        type
                      }
                      rounds{
                        pools{
                          date
                          layoutVersion{
                            layout{
                              name
                              latestVersion{
                                holes{
                                  par
                                }
                              }
                            }
                          }
                        }
                      }
                      registrationsCount
                      playersCount
											registrations{
												id
												status
											}
                    }
                  }
                }
              }
            }
            `,
					},
				});

				state.managedEvents.data = eventsData.data.data.me.administratorRoles;
				state.managedEvents.loaded = true;
			} catch (err) {
				console.log(err);
			}
		},
	},
	actions: {
		loadFavouriteEvents(action) {
			action.commit("loadFavouriteEvents");
		},
		setEventMessages(action, eventMessages) {
			action.commit("setEventMessages", eventMessages);
		},
		setReturnToRouteData(action, routeInfo) {
			action.commit("setReturnToRouteData", routeInfo);
		},
		removeWatchedEvent(action, eventId) {
			action.commit("removeWatchedEvent", eventId);
		},
		addWatchedEvent(action, eventId) {
			action.commit("addWatchedEvent", eventId);
		},
		fetchWatchedEvents(action) {
			action.commit("fetchWatchedEvents");
		},
		loadManagedEvents(action) {
			action.commit("loadManagedEvents");
		},
		loadManagedEventData(action, payload) {
			action.commit("loadManagedEventData", payload);
		},
		disableEmailVerificationBanner(action) {
			action.commit("hideEmailVerificationBanner");
		},
		hideEmailVerificationBanner(action) {
			if (localStorage.getItem("dataFetchedTimestamps")) {
				let timeStamps = JSON.parse(localStorage.getItem("dataFetchedTimestamps"));
				timeStamps.closedDateTime = moment();
				localStorage.setItem("dataFetchedTimestamps", JSON.stringify(timeStamps));
				action.commit("hideEmailVerificationBanner");
			} else {
				let timeStamps = {
					closedDateTime: moment(),
				};

				localStorage.setItem("dataFetchedTimestamps", JSON.stringify(timeStamps));
				action.commit("hideEmailVerificationBanner");
			}
		},
		updateUserEventInformation(action) {
			action.commit("updateUserEventInformation");
		},
		authCheck(action) {
			if (localStorage.getItem("userObject")) {
				if (moment(JSON.parse(localStorage.getItem("userObject")).expireAt).isBefore()) {
					action.commit("logout");
				}
			} else {
				action.commit("logout");
			}
		},
		showMessage(action, messageObject) {
			action.commit("showMessage", messageObject);
		},
		hideMessage(action) {
			action.commit("hideMessage");
		},
		authenticationState(action, bool) {
			action.commit("authenticationState", bool);
		},
		toggleMobileMenu(action, bool) {
			action.commit("toggleMobileMenu", bool);
		},

		checkForOpenGroups(action, bypass) {
			if (localStorage.getItem("dataFetchedTimestamps")) {
				let timeStamps = JSON.parse(localStorage.getItem("dataFetchedTimestamps"));

				if (moment(timeStamps.scorecardCheck).add(15, "seconds").isBefore() || bypass) {
					action.commit("activeScorecards");

					timeStamps.scorecardCheck = moment();
					localStorage.setItem("dataFetchedTimestamps", JSON.stringify(timeStamps));
				}
			} else {
				let timeStamps = {
					scorecardCheck: moment(),
				};

				localStorage.setItem("dataFetchedTimestamps", JSON.stringify(timeStamps));
				action.commit("activeScorecards");
			}
		},
		setMarshalls(action, marshall) {
			action.commit("setMarshalls", marshall);
		},
		setFooterState(action, bool) {
			action.commit("setFooterState", bool);
		},
		setMainNavState(action, bool) {
			action.commit("setMainNavState", bool);
		},
		setEventInfo(action, information) {
			action.commit("setEventInfo", information);
		},
		showEventInfo(action, bool) {
			action.commit("showEventInfo", bool);
		},
		showEventInfoBody(action, bool) {
			action.commit("showEventInfoBody", bool);
		},
		setTourDivisions(action, divisions) {
			action.commit("setTourDivisions", divisions);
		},
		setSessionInfo(action, sessionInfo) {
			action.commit("setSessionInfo", sessionInfo);
		},
		setUserInfo(action, userInfo) {
			action.commit("setUserInfo", userInfo);
		},
		toggleLoading(action, bool) {
			action.commit("toggleLoading", bool);
		},
		escapePressed(action) {
			action.commit("escapePressed");
		},
		deviceWidth(action) {
			action.commit("deviceWidth");
		},
		logout(action) {
			action.commit("logout");
		},
	},
	getters: {
		favouriteEvents: (state) => {
			return state.user.favouriteEvents;
		},
		eventMessages: (state) => {
			return state.eventMessages;
		},
		onTjingMobile: (state) => {
			return state.utility.onTjingMobile;
		},
		returnToRoute: (state) => {
			return state.returnToRoute;
		},
		watchedEvents: (state) => {
			return state.user.watchedEvents;
		},
		watchedEventsLoaded: (state) => {
			return state.user.watchedEventsLoaded;
		},
		managedEvents: (state) => {
			return state.managedEvents;
		},
		managedEvent: (state) => {
			return state.currentlyManagedEvent;
		},
		deviceWidth: (state) => {
			return state.utility.deviceWidth;
		},
		showEmailVerificationBanner: (state) => {
			return state.user.emailVerifyInformation.showBanner;
		},
		registrations: (state) => {
			return state.user.registrations;
		},
		tourPasses: (state) => {
			return state.user.tourPasses;
		},
		tourDivisions: (state) => {
			return state.tourDivisions;
		},
		showMessage: (state) => {
			return state.messages;
		},
		showItems: (state) => {
			return state.utility;
		},
		openPools: (state) => {
			return state.user.openPools;
		},
		marshallGroups: (state) => {
			return state.user.isMarshall;
		},
		showMobileMenu: (state) => {
			return state.utility.showMobileMenu;
		},
		userScorecards: (state) => {
			return state.user.scorecards;
		},
		showFooter: (state) => {
			return state.utility.showFooter;
		},
		showTopNav: (state) => {
			return state.utility.showTopNav;
		},
		eventInformation: (state) => {
			return state.eventInformation;
		},
		userInitials: (state, getters) => {
			if (getters.isUserLoggedIn) {
				return state.user.firstName.charAt(0) + state.user.lastName.charAt(0);
			}
		},
		escapePressed: (state) => {
			return state.utility.escapePressed;
		},
		userInfo: (state) => {
			return state.user;
		},
		isUserLoggedIn: (state) => {
			return state.user.isAuthenticated;
		},
	},
});
