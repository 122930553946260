<template>
  <button
    @click="emitClick()"
    class="StandardBorderedButton"
    :class="{
      sunrise: color,
      disabled: disabled,
      fluid: fluid,
      'desktop-fluidity': desktopFluidity,
      forward: forwardIcon,
    }"
  >
    <div class="lds-ellipsis" v-if="locked">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <template v-else>
      <p>{{ title }}</p>
      <Arrow v-if="forwardIcon" />
    </template>
  </button>
</template>

<script>
import Arrow from "@/assets/icons/Arrow";
export default {
  name: "StandardSolidButton",
  props: [
    "title",
    "disabled",
    "fluid",
    "desktopFluidity",
    "forwardIcon",
    "backwardsIcon",
    "color",
    "locked",
  ],
  components: {
    Arrow,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    emitClick() {
      if (!this.locked && !this.disabled) {
        this.$emit("click");
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 6px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

.sunrise {
  &.StandardBorderedButton {
    background-color: $sunrise;
    border: 1px solid $sunrise;

    &.disabled {
      border-color: $sleet;
      color: $sleet;
      cursor: default;
    }

    &:hover {
      border-color: $sunrise;
      background-color: $sunrise;
    }
  }
}
.StandardBorderedButton {
  user-select: none;
  background-color: $twilight;
  border: 1px solid $twilight;
  color: white;
  height: 48px;
  transition: 0.3s;
  letter-spacing: 0.16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    font-size: 16px;
    @include Gilroy-Bold;
  }

  &.forward {
    svg {
      margin-left: 9px;
    }
  }

  &.fluid {
    width: auto;
    padding: 0 20px;
  }

  &.disabled {
    border-color: $sleet;
    color: $sleet;
    cursor: default;
  }

  &:hover {
    border-color: $twilightshade;
    background-color: $twilightshade;
  }
}

@media (min-width: 1200px) {
  .StandardBorderedButton {
    &.desktop-fluidity {
      width: auto;
    }
  }
}
</style>
