// Controlls the apps modals
const modals = {
  actions: {
    hideAllModals(modal) {
      modal.commit("hideAllModals");
    },
    toggleForgotPasswordModal(modal, bool) {
      modal.commit("toggleForgotPasswordModal", bool);
    },
    toggleRegisterModal(modal, bool) {
      modal.commit("toggleRegisterModal", bool);
    },
    toggleLoginModal(modal, bool) {
      modal.commit("toggleLoginModal", bool);
    },
    showModal(modal, theModal) {
      modal.commit("showModal", theModal);
    },
    hideModal(modal, theModal) {
      modal.commit("hideModal", theModal);
    },
    toggleModal(modal, theModal) {
      modal.commit("toggleModal", theModal);
    },
    toggleScorecardModal(modal, theModal) {
      modal.commit("toggleScorecardModal", theModal);
    },
  },
  mutations: {
    hideAllModals(state) {
      state.showForgotPasswordModal = false;
      state.showPasswordModal = false;
      state.showPinModal = false;
    },
    toggleScorecardModal(state, bool) {
      state.showScorecardModal = bool;
    },
    toggleForgotPasswordModal(state, bool) {
      state.showForgotPasswordModal = bool;
    },
    toggleRegisterModal(state, bool) {
      state.showRegisterModal = bool;
    },
    toggleLoginModal(state, bool) {
      state.showLoginModal = bool;
    },
    showModal(state, theModal) {
      state[theModal] = true;
    },
    hideModal(state, theModal) {
      state[theModal] = false;
    },
    toggleModal(state, theModal) {
      state[theModal] = !state[theModal];
    },
  },
  getters: {
    isAModalOpen: (state) => {
      if (state.showScorecardModal || state.showWeeklyModal || state.showPinModal || state.showPasswordModal) {
        return true;
      } else return false;
    },
    modalStatus: (state) => {
      return state;
    },
  },
  state: {
    closeAllModals: false,
    showScorecardModal: false,
    showWeeklyModal: false,
    showPinModal: false,
    showPasswordModal: false,
  },
};

export default modals;
