<template>
  <div class="donation-banner" v-if="showBanner">
    <p class="small">
      We need your help. A small donation makes a big difference. Donate via
      <a href="https://www.paypal.me/tjingapp" target="_blank" rel="noreferrer"
        >PayPal</a
      >
      or
      <span @click="$refs.swish.open()">Swish</span>
    </p>
    <span @click="hideBanner()">
      <Crossmark />
    </span>

    <sweet-modal
      :enable-mobile-fullscreen="false"
      ref="swish"
      hide-close-button
    >
      <img class="donation-qr-code" src="@/assets/img/tjing_swish_qr.png" />
      <p class="donation-swish-text">
        Scan the QR-code in your Swish app or Swish directly to:
        <b> 1236371124</b>
      </p>

      <StandardSolidButton
        title="Close"
        :fluid="false"
        @click="$refs.swish.close()"
      />
    </sweet-modal>
  </div>
</template>

<script>
import moment from "moment";
import Crossmark from "@/assets/icons/Crossmark";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
export default {
  name: "DonationBanner",
  components: {
    Crossmark,
    StandardSolidButton,
  },
  data() {
    return {
      showBanner: true,
    };
  },
  computed: {},
  methods: {
    hideBanner() {
      localStorage.setItem("showDonationBanner", JSON.stringify(moment()));
      this.showBanner = false;
    },
  },
  beforeMount() {
    if (localStorage.getItem("showDonationBanner")) {
      const timeStamp = JSON.parse(localStorage.getItem("showDonationBanner"));

      this.showBanner = moment().subtract(1, "months").isAfter(timeStamp);
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss">
.donation-qr-code {
  width: 100%;
  margin-bottom: 18px;
}

.donation-banner {
  .sweet-content-content {
    .donation-swish-text {
      text-align: center;
      margin-bottom: 32px;
      margin: 0 0 32px 0;
      max-width: 100%;
      line-height: 1.4rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/Typography";
.donation-banner {
  background: $fog;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    line-height: 1.2rem;
    margin-left: auto;
    max-width: 75%;
    text-align: center;
    span,
    a {
      color: $midnight;
      text-decoration: underline;
      cursor: pointer;
      @include Gilroy-Bold;
    }
  }

  span {
    cursor: pointer;
    margin-left: auto;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .donation-banner {
    padding: 12px;
  }
}
</style>
