<template>
  <div class="verify-email-container">
    <div class="unverified" v-if="!showResendVerification">
      <p class="title">
        <b>Please verify your account</b>
        <img src="@/assets/img/close-dark.svg" alt @click="hideBanner()" />
      </p>
      <p>
        Don’t be alarmed, we just want to know you are you. Click
        <span @click="resendVerification()">here</span> and we’ll send you an
        email to verify.
      </p>
    </div>
    <div class="resend-verification" v-if="showResendVerification">
      <p class="title">
        <b>Please check your inbox</b>
        <img src="@/assets/img/close-dark.svg" alt @click="hideBanner()" />
      </p>
      <p>
        An email was sent to:
        <b>{{ $store.state.user.email }}.</b> Didn’t receive an email? Click to
        <span @click="resendVerification()">resend</span>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyEmailBanner",
  data() {
    return {
      showResendVerification: false,
    };
  },
  computed: {},
  methods: {
    resendVerification() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation{
              SendVerifyEmail
            }
          `,
        },
      }).then(() => {
        this.showResendVerification = true;
      });
    },
    hideBanner() {
      this.$store.dispatch("hideEmailVerificationBanner");
    },
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.verify-email-container {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 130px;
  background-color: $sun;
  padding: 20px 15px;
  justify-content: flex-start;
  z-index: 10002232003 !important;
  span {
    cursor: pointer;
    @include Gilroy-Bold;
    text-decoration: underline;
  }

  .unverified,
  .resend-verification {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .title {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      width: 100%;
      font-size: 14px;
    }

    img {
      height: 16px;
      display: inline;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .verify-email-container {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-color: $sun;
    padding: 20px 35px;
    justify-content: flex-start;

    span {
      cursor: pointer;
      @include Gilroy-Bold;
      text-decoration: underline;
    }

    .unverified,
    .resend-verification {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .title {
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
      }
      p {
        width: 100%;
        font-size: 14px;
      }

      img {
        height: 24px;
        display: inline;
      }
    }
  }
}
</style>
