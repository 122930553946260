const colors = {
  actions: {},
  getters: {},
  state: {
    parColors: {
      ace: {
        hex: "#FFCA4C",
        rgba: "",
      },
      albatross: {
        hex: "#FF8B6B",
        rgba: "",
      },
      eagle: {
        hex: "#4DC3FF",
        rgba: "",
      },
      birdie: {
        hex: "#4CDBB7",
        rgba: "",
      },
      par: {
        hex: "#ECEDF0",
        rgba: "",
      },
      parDark: {
        hex: "#C1C1C4",
        rgba: "",
      },
      bogey: {
        hex: "#FF7FB1",
        rgba: "",
      },
      doubleBogey: {
        hex: "#FF599A",
        rgba: "",
      },
      tripleBogey: {
        hex: "#FF3383",
        rgba: "",
      },
    },
    primary: {
      midnight: {
        hex: "#2d323c",
        rgba: [45, 50, 60, 1],
      },
      sky: {
        hex: "#3cdceb",
        rgba: [60, 220, 235, 1],
      },
      avocado: {
        hex: "#00e1a5",
        rgba: [0, 225, 165, 1],
      },
      ocean: {
        hex: "#05B5BC",
        rgba: [5, 181, 188, 1],
      },
      twilight: {
        hex: "#B40068",
        rgba: [180, 0, 104, 1],
      },
    },
    secondary: {
      elephant: {
        hex: "#7d8296",
        rgba: [125, 130, 150, 1],
      },
      mouse: {
        hex: "#d2d7dc",
        rgba: [210, 215, 220, 1],
      },
      mist: {
        hex: "#f4f7f7",
        rgba: [244, 247, 247, 1],
      },
      watermelon: {
        hex: "#ff326e",
        rgba: [255, 50, 110, 1],
      },
      dusk: {
        hex: "#FF0064",
        rgba: [255, 0, 100, 1],
      },
      peach: {
        hex: "#ff9669",
        rgba: [255, 150, 105, 1],
      },
      sun: {
        hex: "#ffe15a",
        rgba: [255, 225, 90, 1],
      },
    },
    divisions: {
      fallback: {
        hex: "#2d323c",
        rgba: [45, 50, 60, 1],
      },
      MPO: {
        hex: "",
        rgba: [60, 220, 235, 1],
      },
      MP40: {
        hex: "",
        rgba: [255, 50, 110, 1],
      },
      MP50: {
        hex: "",
        rgba: [255, 50, 110, 0.8],
      },
      MP55: {
        hex: "",
        rgba: [255, 50, 110, 0.8],
      },
      MP60: {
        hex: "",
        rgba: [255, 50, 110, 0.6],
      },
      MP65: {
        hex: "",
        rgba: [255, 50, 110, 0.6],
      },
      MP70: {
        hex: "",
        rgba: [255, 50, 110, 0.4],
      },
      MP75: {
        hex: "",
        rgba: [255, 50, 110, 0.4],
      },
      MP80: {
        hex: "",
        rgba: [255, 50, 110, 0.2],
      },
      FPO: {
        hex: "",
        rgba: [0, 225, 165, 1],
      },
      FP40: {
        hex: "",
        rgba: [0, 225, 165, 0.8],
      },
      FP50: {
        hex: "",
        rgba: [0, 225, 165, 0.6],
      },
      FP55: {
        hex: "",
        rgba: [0, 225, 165, 0.6],
      },
      FP60: {
        hex: "",
        rgba: [0, 225, 165, 0.4],
      },
      FP65: {
        hex: "",
        rgba: [0, 225, 165, 0.4],
      },
      FP70: {
        hex: "",
        rgba: [0, 225, 165, 0.2],
      },
      MA1: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA2: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA3: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA4: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA40: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA50: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA55: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA60: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA65: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      MA70: {
        hex: "",
        rgba: [255, 150, 105, 1],
      },
      FA1: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA2: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA3: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA4: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA40: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA50: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA55: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA60: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA65: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      FA70: {
        hex: "",
        rgba: [0, 120, 255, 1],
      },
      MJ18: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ18: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      MJ15: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ15: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      MJ12: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ12: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      MJ10: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ10: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      MJ08: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ08: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ06: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
      FJ6: {
        hex: "",
        rgba: [255, 255, 90, 1],
      },
    },
  },
};

export default colors;
