// Controlls the apps modals

import moment from "moment";
const builder = {
	state: {
		builderVersion: 1,
		startedWizardType: "",
		pdgaEventTypes: [],
		tourTypes: [],
		divisionTypes: [],
		currencies: [],
		oneOffSettings: {},
		weeklySettings: {},
		tourSettings: {},
		tour: {},
	},
	actions: {
		setLeagueStartDate: {
			root: true,
			handler(action, payload) {
				action.commit("setLeagueStartDate", payload);
			},
		},
		updateConnectedAccountId: {
			root: true,
			handler(action, payload) {
				action.commit("updateConnectedAccountId", payload);
			},
		},
		updateRegOpeningOffset: {
			root: true,
			handler(action, payload) {
				action.commit("updateRegOpeningOffset", payload);
			},
		},
		removeleaguePlayDay: {
			root: true,
			handler(action, payload) {
				action.commit("removeleaguePlayDay", payload);
			},
		},
		addleaguePlayDay: {
			root: true,
			handler(action, payload) {
				action.commit("addleaguePlayDay", payload);
			},
		},
		setLeagueInt: {
			root: true,
			handler(action, payload) {
				action.commit("setLeagueInt", payload);
			},
		},
		setwizardType: {
			root: true,
			handler(action, payload) {
				action.commit("setwizardType", payload);
			},
		},
		saveFinishedBuild: {
			root: true,
			handler(action, payload) {
				action.commit("saveFinishedBuild", payload);
			},
		},
		clearUnfinishedBuild: {
			root: true,
			handler(action, payload) {
				action.commit("clearUnfinishedBuild", payload);
			},
		},
		saveUnfinishedBuild: {
			root: true,
			handler(action, payload) {
				action.commit("saveUnfinishedBuild", payload);
			},
		},
		loadUnfinishedBuild: {
			root: true,
			handler(action, payload) {
				action.commit("loadUnfinishedBuild", payload);
			},
		},
		changeWaitinglistType: {
			root: true,
			handler(action, payload) {
				action.commit("changeWaitinglistType", payload);
			},
		},
		toggleCheckInEnabled: {
			root: true,
			handler(action, payload) {
				action.commit("toggleCheckInEnabled", payload);
			},
		},
		togglePDGASanctioned: {
			root: true,
			handler(action, payload) {
				action.commit("togglePDGASanctioned", payload);
			},
		},
		deleteEvent: {
			root: true,
			handler(action, payload) {
				action.commit("deleteEvent", payload);
			},
		},
		duplicateEvent: {
			root: true,
			handler(action, payload) {
				action.commit("duplicateEvent", payload);
			},
		},
		toggleEventSectionExpansion: {
			root: true,
			handler(action, payload) {
				action.commit("toggleEventSectionExpansion", payload);
			},
		},
		updateScoringType: {
			root: true,
			handler(action, payload) {
				action.commit("updateScoringType", payload);
			},
		},
		updateScoreBasedOnNumberOfEvents: {
			root: true,
			handler(action, payload) {
				action.commit("updateScoreBasedOnNumberOfEvents", payload);
			},
		},
		updateTourName: {
			root: true,
			handler(action, payload) {
				action.commit("updateTourName", payload);
			},
		},
		updateEventName: {
			root: true,
			handler(action, payload) {
				action.commit("updateEventName", payload);
			},
		},
		updateLeagueName: {
			root: true,
			handler(action, payload) {
				action.commit("updateLeagueName", payload);
			},
		},
		resetBuilderState: {
			root: true,
			handler(action) {
				action.commit("resetBuilderState");
			},
		},
		addBlankEventToTour: {
			root: true,
			handler(action) {
				action.commit("addBlankEventToTour");
			},
		},
		addRoundToEvent: {
			root: true,
			handler(action, payload) {
				action.commit("addRoundToEvent", payload);
			},
		},
		removeRoundFromEvent: {
			root: true,
			handler(action, payload) {
				action.commit("removeRoundFromEvent", payload);
			},
		},
		removePoolFromLeague: {
			root: true,
			handler(action) {
				action.commit("removePoolFromLeague");
			},
		},

		removeRoundFromLeague: {
			root: true,
			handler(action) {
				action.commit("removeRoundFromLeague");
			},
		},
		addRoundToLeague: {
			root: true,
			handler(action, payload) {
				action.commit("addRoundToLeague", payload);
			},
		},
		addPoolToLeague: {
			root: true,
			handler(action) {
				action.commit("addPoolToLeague");
			},
		},
		addPoolToRounds: {
			root: true,
			handler(action, payload) {
				action.commit("addPoolToRounds", payload);
			},
		},
		removePoolFromRounds: {
			root: true,
			handler(action, payload) {
				action.commit("removePoolFromRounds", payload);
			},
		},

		applyLeagueLayoutToPool: {
			root: true,
			handler(action, payload) {
				action.commit("applyLeagueLayoutToPool", payload);
			},
		},
		applyLayoutToPool: {
			root: true,
			handler(action, payload) {
				action.commit("applyLayoutToPool", payload);
			},
		},
		updateLeaguePoolStartTime: {
			root: true,
			handler(action, payload) {
				action.commit("updateLeaguePoolStartTime", payload);
			},
		},
		updateLeaguePoolStartMethod: {
			root: true,
			handler(action, payload) {
				action.commit("updateLeaguePoolStartMethod", payload);
			},
		},
		updatePoolStartMethod: {
			root: true,
			handler(action, payload) {
				action.commit("updatePoolStartMethod", payload);
			},
		},
		updateLeagueDay: {
			root: true,
			handler(action, payload) {
				action.commit("updateLeagueDay", payload);
			},
		},
		updatePoolStartTime: {
			root: true,
			handler(action, payload) {
				action.commit("updatePoolStartTime", payload);
			},
		},
		updateLeagueSettings: {
			root: true,
			handler(action, payload) {
				action.commit("updateLeagueSettings", payload);
			},
		},
		updateTourSettings: {
			root: true,
			handler(action, payload) {
				action.commit("updateTourSettings", payload);
			},
		},
		updateoneOffSettings: {
			root: true,
			handler(action, payload) {
				action.commit("updateoneOffSettings", payload);
			},
		},
		updateLeagueBool: {
			root: true,
			handler(action, payload) {
				action.commit("updateLeagueBool", payload);
			},
		},
		updateBoolOnEvent: {
			root: true,
			handler(action, payload) {
				action.commit("updateBoolOnEvent", payload);
			},
		},

		applyCityToLeague: {
			root: true,
			handler(action, payload) {
				action.commit("applyCityToLeague", payload);
			},
		},
		applyCityToEvent: {
			root: true,
			handler(action, payload) {
				action.commit("applyCityToEvent", payload);
			},
		},
		removeCityFromLeague: {
			root: true,
			handler(action) {
				action.commit("removeCityFromLeague");
			},
		},
		removeCityFromEvent: {
			root: true,
			handler(action, payload) {
				action.commit("removeCityFromEvent", payload);
			},
		},
		updateGlobalData: {
			root: true,
			handler(action, payload) {
				action.commit("updateGlobalData", payload);
			},
		},
		updateEventStringValue: {
			root: true,
			handler(action, payload) {
				action.commit("updateEventStringValue", payload);
			},
		},
		updateTourType: {
			root: true,
			handler(action, payload) {
				action.commit("updateTourType", payload);
			},
		},
		updateEventPDGAType: {
			root: true,
			handler(action, payload) {
				action.commit("updateEventPDGAType", payload);
			},
		},
		updateEventTimeField: {
			root: true,
			handler(action, payload) {
				action.commit("updateEventTimeField", payload);
			},
		},

		addDivision: {
			root: true,
			handler(action, payload) {
				action.commit("addDivision", payload);
			},
		},
		updateDivision: {
			root: true,
			handler(action, payload) {
				action.commit("updateDivision", payload);
			},
		},
		removeDivision: {
			root: true,
			handler(action, payload) {
				action.commit("removeDivision", payload);
			},
		},
		updateRegistrationMessage: {
			root: true,
			handler(action, payload) {
				action.commit("updateRegistrationMessage", payload);
			},
		},
		updateRegistrationType: {
			root: true,
			handler(action, payload) {
				action.commit("updateRegistrationType", payload);
			},
		},
		pasteContactDetails: {
			root: true,
			handler(action, payload) {
				action.commit("pasteContactDetails", payload);
			},
		},
	},
	mutations: {
		setLeagueStartDate(state, payload) {
			state.weeklySettings.startDate = payload;
		},
		updateConnectedAccountId(state, payload) {
			state.tour.connectedAccountId = payload;
		},
		removeleaguePlayDay(state, payload) {
			state.weeklySettings.startDates = state.weeklySettings.startDates.filter((date) => {
				return moment(date.date).isoWeekday() != payload;
			});
		},
		addleaguePlayDay(state, payload) {
			state.weeklySettings.startDates.push(payload);
		},
		setLeagueInt(state, payload) {
			state.weeklySettings[payload.property] = payload.value;
		},
		setwizardType(state, payload) {
			state.startedWizardType = payload;
		},
		saveFinishedBuild(state) {
			//console.log(state.tour);
		},
		clearUnfinishedBuild(state) {
			localStorage.removeItem("unfinishedbuild");
		},
		saveUnfinishedBuild(state) {
			localStorage.setItem("unfinishedbuild", JSON.stringify(state));
		},
		loadUnfinishedBuild(state) {
			let newState = JSON.parse(localStorage.getItem("unfinishedbuild"));

			if (newState != null && newState.builderVersion == state.builderVersion) {
				state.tour = newState.tour;
				state.startedWizardType = newState.startedWizardType;
				state.tourSettings = newState.tourSettings;
				state.weeklySettings = newState.weeklySettings;
				state.oneOffSettings = newState.oneOffSettings;
			}
		},

		changeWaitinglistType(state, payload) {
			state.tour.events[payload.eventIndex].waitinglistType = payload.value;
		},
		toggleCheckInEnabled(state, payload) {
			if (payload.value == false) {
				state.tour.events[payload.eventIndex].checkInStartsAt = null;
				state.tour.events[payload.eventIndex].checkInEndsAt = null;
			}
			state.tour.events[payload.eventIndex].utility.checkInEnabled = payload.value;
		},
		togglePDGASanctioned(state, payload) {
			if (payload.value == true) {
				state.tour.events[payload.eventIndex].pdgaType = "PDGA_A";
			} else {
				state.tour.events[payload.eventIndex].pdgaType = null;
			}
			state.tour.events[payload.eventIndex].utility.pdgaEnabled = payload.value;
		},
		pasteContactDetails(state, payload) {
			state.tour.events[payload.targetEventIndex].tournamentDirectorName = state.tour.events[payload.fromEventIndex].tournamentDirectorName;
			state.tour.events[payload.targetEventIndex].assistantTournamentDirectorName =
				state.tour.events[payload.fromEventIndex].assistantTournamentDirectorName;
			state.tour.events[payload.targetEventIndex].assistantTournamentDirectorEmail =
				state.tour.events[payload.fromEventIndex].assistantTournamentDirectorEmail;
			state.tour.events[payload.targetEventIndex].assistantTournamentDirectorPhone =
				state.tour.events[payload.fromEventIndex].assistantTournamentDirectorPhone;
			state.tour.events[payload.targetEventIndex].phone = state.tour.events[payload.fromEventIndex].phone;
			state.tour.events[payload.targetEventIndex].email = state.tour.events[payload.fromEventIndex].email;
			state.tour.events[payload.targetEventIndex].website = state.tour.events[payload.fromEventIndex].website;
			state.tour.events[payload.targetEventIndex].location = state.tour.events[payload.fromEventIndex].location;
			state.tour.events[payload.targetEventIndex].pdgaEventId = state.tour.events[payload.fromEventIndex].pdgaEventId;
			state.tour.events[payload.targetEventIndex].description = state.tour.events[payload.fromEventIndex].description;
		},
		deleteEvent(state, payload) {
			state.tour.events.splice(payload, 1);
		},
		duplicateEvent(state, payload) {
			state.tour.events.push(payload);
		},
		toggleEventSectionExpansion(state, payload) {
			state.tour.events[payload.eventIndex].utility[payload.property] = payload.value;
		},
		updateRegistrationMessage(state, payload) {
			state.tour.allowRegistrationMessage = payload;
		},
		updateRegistrationType(state, payload) {
			state.tour.registrationMethod = payload;
		},
		removeDivision(state, payload) {
			state.tour.divisions = state.tour.divisions.filter((division) => division.type != payload);
		},
		updateDivision(state, payload) {
			state.tour.divisions = [...state.tour.divisions.filter((division) => division.type != payload.type), payload];
		},
		addDivision(state, payload) {
			state.tour.divisions.push(payload);
		},
		updateEventTimeField(state, payload) {
			state.tour.events[payload.eventIndex][payload.property] = payload.date;
		},
		updateTourType(state, payload) {
			state.tour.type = payload;
		},
		updateEventPDGAType(state, payload) {
			state.tour.events[payload.eventIndex].pdgaType = payload.pdgaType;
		},
		updateEventStringValue(state, payload) {
			state.tour.events[payload.eventIndex][payload.property] = payload.value;
		},
		updateGlobalData(state, payload) {
			state.pdgaEventTypes = payload.pdgaEventTypes;
			state.tourTypes = payload.tourTypes;
			state.divisionTypes = payload.divisionTypes;
			state.currencies = payload.currencies;
		},
		removeCityFromLeague(state) {
			state.weeklySettings.city = null;
		},
		removeCityFromEvent(state, payload) {
			state.tour.events[payload].city = null;
		},
		applyCityToLeague(state, payload) {
			state.weeklySettings.city = payload;
		},
		applyCityToEvent(state, payload) {
			state.tour.events[payload.eventIndex].city = payload.city;
		},
		updateLeagueBool(state, payload) {
			state.weeklySettings[payload.property] = payload.value;
		},
		updateBoolOnEvent(state, payload) {
			state.tour.events[payload.eventIndex][payload.property] = payload.value;
		},
		updateLeagueSettings(state, payload) {
			state.weeklySettings[payload.property] = payload.bool;
		},
		updateTourSettings(state, payload) {
			state.tourSettings[payload.property] = payload.bool;
		},
		updateoneOffSettings(state, payload) {
			state.oneOffSettings[payload.property] = payload.bool;
		},
		updateRegOpeningOffset(state, payload) {
			state.weeklySettings.startDates[payload.index][payload.property] = payload.value;
		},
		updateLeagueDay(state, payload) {
			state.weeklySettings.startDates[payload.dayIndex][payload.property] = moment(payload.value).toISOString();
		},
		updateLeaguePoolStartMethod(state, payload) {
			state.weeklySettings.startDates[payload.eventIndex].rounds[payload.roundIndex].pools[payload.poolIndex].startMethod = payload.startMethod;
		},
		updateLeaguePoolStartTime(state, payload) {
			state.weeklySettings.startDates[payload.eventIndex].rounds[payload.roundIndex].pools[payload.poolIndex].time = payload.date;
		},
		updatePoolStartTime(state, payload) {
			state.tour.events[payload.eventIndex].rounds[payload.roundIndex].pools[payload.poolIndex].date = payload.date;
		},
		updatePoolStartMethod(state, payload) {
			state.tour.events[payload.eventIndex].rounds[payload.roundIndex].pools[payload.poolIndex].startMethod = payload.startMethod;
		},
		applyLeagueLayoutToPool(state, payload) {
			state.weeklySettings.startDates[payload.eventIndex].rounds[payload.roundIndex].pools[payload.poolIndex].layout = payload.layout;
		},
		applyLayoutToPool(state, payload) {
			state.tour.events[payload.eventIndex].rounds[payload.roundIndex].pools[payload.poolIndex].layout = payload.layout;
		},
		removePoolFromLeague(state) {
			state.weeklySettings.startDates.forEach((day) => {
				day.rounds.forEach((round) => {
					round.pools.pop();
				});
			});
		},
		removeRoundFromLeague(state) {
			state.weeklySettings.startDates.forEach((day) => {
				day.rounds.pop();
			});
		},
		addRoundToLeague(state, payload) {
			state.weeklySettings.startDates.forEach((day) => {
				let emptyPools = [];

				for (let index = 0; index < payload; index++) {
					emptyPools.push({ time: null, layout: null, startMethod: "SHOTGUN" });
				}
				day.rounds.push({ pools: emptyPools });
			});
		},
		addPoolToLeague(state) {
			state.weeklySettings.startDates.forEach((day) => {
				day.rounds.forEach((round) => {
					round.pools.push({ time: null, layout: null, startMethod: "SHOTGUN" });
				});
			});
		},
		addPoolToRounds(state, payload) {
			state.tour.events[payload.eventIndex].rounds.forEach((round) => {
				round.pools.push({ date: null, layout: null, startMethod: "SHOTGUN" });
			});
		},
		removePoolFromRounds(state, payload) {
			state.tour.events[payload.eventIndex].rounds.forEach((round) => {
				round.pools.pop();
			});
		},
		removeRoundFromEvent(state, payload) {
			state.tour.events[payload.eventIndex].rounds.pop();
		},
		addRoundToEvent(state, payload) {
			const numberOfPoolsToAdd = state.tour.events[payload.eventIndex].rounds[0].pools.length;
			const defaultPool = { date: null, layout: null, startMethod: "SHOTGUN" };
			let pools = [];

			for (let index = 0; index < numberOfPoolsToAdd; index++) {
				pools.push(defaultPool);
			}

			state.tour.events[payload.eventIndex].rounds.push({
				pools,
			});
		},
		updateScoringType(state, payload) {
			state.tour.scoringType = payload;
		},
		updateScoreBasedOnNumberOfEvents(state, payload) {
			state.tour.scoreBasedOnNrOfEvents = payload;
		},
		updateTourName(state, payload) {
			state.tour.name = payload;
		},
		updateEventName(state, payload) {
			state.tour.events[payload.eventIndex].name = payload.name;
		},
		updateLeagueName(state, payload) {
			state.weeklySettings.name = payload;
		},
		resetBuilderState(state) {
			localStorage.removeItem("unfinishedbuild");
			state.startedWizardType = "";
			state.oneOffSettings = {
				verifier: true,
				signature: true,
				payments: false,
				checkInEnabled: false,
				pdgaTypeEnabled: false,
			};
			state.weeklySettings = {
				name: "",
				city: null,
				poolsCount: 1,
				roundsCount: 1,
				autoOpenRegistration: true,
				startDate: null,
				startDates: [],
				numberOfWeeks: 20,
				checkInOpensMinutesBefore: 180,
				checkInClosesMinutesBefore: 15,
				pdgaType: null,
				isVerifyMode: true,
				isResultSigningEnabled: true,
				payments: false,
				pdgaSanctioned: false,
				checkInEnabled: false,
				openRegistrationDatesUsed: true,
			};
			state.tourSettings = {
				verifier: true,
				signature: true,
				payments: false,
			};
			state.tour = {
				name: null,
				type: null,
				scoringType: "AVERAGE_THROWS",
				registrationMethod: "LIST",
				scoreBasedOnNrOfEvents: null,
				paymentType: "MANUAL",
				connectedAccountId: null,
				automaticRefunds: true,
				divisions: [
					{
						type: "MPO",
						name: "Pro Open",
						maxSpots: null,
						reservedSpots: null,
						registrationFee: { value: 0, currency: "SEK" },
						tourPassFee: { value: 0 },
						isDeletable: true,
					},
				],
				events: [
					{
						utility: {
							expanded: true,
							settingsExpanded: false,
							contactDetailsExpanded: false,
							pdgaEnabled: false,
							checkInEnabled: false,
						},
						name: null,
						description: null,
						maxSpots: 72,
						maxWildcards: null,
						rounds: [
							{
								pools: [
									{
										date: null,
										layout: null,
										startMethod: "SHOTGUN",
									},
								],
							},
						],
						registrationOpenAt: null,
						tournamentDirectorName: null,
						assistantTournamentDirectorName: null,
						assistantTournamentDirectorEmail: null,
						assistantTournamentDirectorPhone: null,
						phone: null,
						email: null,
						registrationFeesInfo: null,
						paymentInfo: null,
						location: null,
						website: null,
						isVerifyMode: true,
						isResultSigningEnabled: true,
						groupSize: 4,
						connectedAccountId: null,
						city: null,
						checkInStartsAt: null,
						checkInEndsAt: null,
						autoOpenRegistration: false,
						autoCloseInactivePools: true,
						pdgaEventId: null,
						pdgaType: null,
						waitinglistType: "DIVISION",
					},
				],
			};
		},
		addBlankEventToTour(state) {
			const newEvent = {
				utility: {
					expanded: true,
					settingsExpanded: false,
					contactDetailsExpanded: false,
					pdgaEnabled: false,
					checkInEnabled: false,
				},
				name: null,
				description: null,
				maxSpots: 72,
				maxWildcards: null,
				rounds: [
					{
						pools: [
							{
								date: null,
								layout: null,
								startMethod: "SHOTGUN",
							},
						],
					},
				],
				registrationOpenAt: null,
				tournamentDirectorName: null,
				assistantTournamentDirectorName: null,
				assistantTournamentDirectorEmail: null,
				assistantTournamentDirectorPhone: null,
				phone: null,
				email: null,
				registrationFeesInfo: null,
				paymentInfo: null,
				location: null,
				website: null,
				isVerifyMode: true,
				isResultSigningEnabled: true,
				groupSize: 4,
				connectedAccountId: null,
				city: null,
				checkInStartsAt: null,
				checkInEndsAt: null,
				autoOpenRegistration: false,
				autoCloseInactivePools: true,
				pdgaEventId: null,
				pdgaType: null,
				waitinglistType: "DIVISION",
			};
			state.tour.events.push(newEvent);
		},
	},
	getters: {
		tourSettings(state) {
			state.tourSettings;
		},
		leaguePlayDays(state) {
			if (state.weeklySettings && state.weeklySettings.startDates) {
				return state.weeklySettings.startDates.sort((a, b) => moment(a.date).isoWeekday() - moment(b.date).isoWeekday());
			}
			return [];
		},
		leagueSettings(state) {
			return state.weeklySettings;
		},
		tour(state) {
			return state;
		},
	},
};

export default builder;
