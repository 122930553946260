<template>
  <div class="negative-margin">
    <DonationBanner />
    <div class="row pageHero">
      <section class="hero">
        <h1>
          Experience Disc Golf like never before.
          <br />On and off the course.
        </h1>
        <h3>
          Tjing gives you the power to build, manage and play any kind of
          tournament, league or event, as well as a casual round with friends.
        </h3>
        <div v-if="!isMobilePlatform()" class="buttonSection">
          <div class="buttonContainer">
            <router-link :to="{ name: 'register' }">
              <button class="shampoo signUpButton">Sign up free</button>
            </router-link>
          </div>
          <div class="buttonContainer">
            <router-link :to="{ name: 'whytjing' }">
              <button class="shampoo signUpButton reversed">Why Tjing?</button>
            </router-link>
          </div>
        </div>
        <div v-if="isMobilePlatform()" class="buttonSectionDeviceSpecific">
          <a
            v-if="shouldShowIOSButton()"
            href="https://apps.apple.com/se/app/tjing/id1277060479"
            class="shampoo buttonExternalLink"
            >Get Tjing for iOS</a
          >
          <a
            v-if="shouldShowAndroidButton()"
            href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
            class="shampoo buttonExternalLink"
            >Get Tjing for Android</a
          >
        </div>
      </section>
    </div>
    <section class="scorecard">
      <div class="scorecardImage">
        <img
          class="scorecardDesktop"
          src="@/assets/img/LiveScoring–Expanded_New.png"
          alt="scorecard"
        />
        <img
          class="scorecardMobile"
          src="@/assets/img/LiveScoring–Mobile_New.png"
          alt="scorecard"
        />
      </div>
    </section>
    <!--
    <section class="scorecard-slider">
      <img src="@/assets/img/LiveScoring–Mobile_New.png" alt="scorecard" />
    </section>-->
    <div class="carousel">
      <carousel :perPage="1" :paginationSize="12">
        <slide data-name="Live scoring and stats">
          <img src="@/assets/img/carouselLiveScoring.png" alt="scorecard" />
        </slide>
        <slide data-name="The Scorecard">
          <img src="@/assets/img/LiveScoring–Mobile_New.png" alt="scorecard" />
        </slide>
        <slide data-name="The Verifier">
          <img src="@/assets/img/carouselVerifier.png" alt="scorecard" />
        </slide>
      </carousel>
    </div>

    <section class="appCTAWrapper withBackgroundColor">
      <h2>
        With it comes live scoring, instant online results, scorecards and
        detailed player stats.
      </h2>
      <h4>
        A smooth digital scoring experience on the course. Track, analyse and
        measure every aspect of your activity. Download free on iOS and Android.
      </h4>
      <div v-if="!isMobilePlatform()" class="imageButtonSection">
        <a href="https://apps.apple.com/se/app/tjing/id1277060479">
          <img
            class="storeButtonImage"
            src="@/assets/img/AppleStoreButtonLogo.png"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
        >
          <img
            class="storeButtonImage"
            src="@/assets/img/GooglePlayButtonLogo.png"
            alt="Google Play"
          />
        </a>
      </div>
      <div v-if="isMobilePlatform()" class="buttonSectionDeviceSpecific">
        <a
          v-if="shouldShowIOSButton()"
          class="imageLink"
          href="https://apps.apple.com/se/app/tjing/id1277060479"
        >
          <img
            class="storeButtonImage"
            src="@/assets/img/AppleStoreButtonLogo.png"
          />
        </a>
        <a
          v-if="shouldShowAndroidButton()"
          class="imageLink"
          href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
        >
          <img
            class="storeButtonImage"
            src="@/assets/img/GooglePlayButtonLogo.png"
            alt="Google Play"
          />
        </a>
      </div>
      <p>
        Requires Apple iOS 10 or higher. Android OS 5.0 or higher. Windows 10
        v1607 or higher.
      </p>
    </section>

    <section class="featureSectionWrapper">
      <h1>What you can do with Tjing</h1>
      <div class="featuresGrid">
        <div class="featureItem">
          <img src="@/assets/img/featureKeepScore.svg" alt="Keep score" />
          <h3>Keep score</h3>
          <h5>
            Keep score on casual rounds with friends or just in practice. Plus
            on all events powered by Tjing.
          </h5>
        </div>
        <div class="featureItem">
          <img
            src="@/assets/img/featureTrackAndAnalyse.svg"
            alt="Track and analyse"
          />
          <h3>Track and analyse</h3>
          <h5>
            Dive deep into your data and measure and compare every aspect of
            your activity.
          </h5>
        </div>

        <div class="featureItem">
          <img
            src="@/assets/img/featureBuildAndManageEvents.svg"
            alt="Build and manage events"
          />
          <h3>Build and manage events</h3>
          <h5>
            Everything you need to build and manage a full size tournament or
            any type of event.
          </h5>
        </div>
        <div class="featureItem">
          <img
            src="@/assets/img/featureVerifyScores.svg"
            alt="Verify scores and sign results"
          />
          <h3>Verify scores and sign results</h3>
          <h5>
            The Verifier™ reduces the chance of incorrect scores. The Signature™
            is used to sign your results.
          </h5>
        </div>
      </div>
    </section>
    <SignUpCTA />
  </div>
</template>

<script>
import SignUpCTA from "@/components/SignUpCTA.vue";
import DonationBanner from "@/components/DonationBanner";
import moment from "moment";

import { Carousel, Slide } from "vue-carousel";

export default {
  name: "home",
  components: {
    SignUpCTA,
    Carousel,
    Slide,
    DonationBanner,
  },
  data() {
    return {
      dateTime: null,
      earliestDate: moment(),
      lastDate: moment().endOf("day").add(1, "years"),
      dataFromUserPlatform: "",
    };
  },
  methods: {
    isMobilePlatform() {
      return /iPhone|iPad|iPod|Android/i.test(this.dataFromUserPlatform);
    },
    shouldShowAndroidButton() {
      if (this.isMobilePlatform) {
        return /iPhone|iPad|iPod/i.test(this.dataFromUserPlatform)
          ? false
          : true;
      }
    },
    shouldShowIOSButton() {
      if (this.isMobilePlatform) {
        return /Android/i.test(this.dataFromUserPlatform) ? false : true;
      }
    },
  },

  beforeRouteEnter(from, to, next) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    next();
  },
  mounted() {
    this.dataFromUserPlatform = navigator.platform + " " + navigator.userAgent;
    // Tawk_API = Tawk_API || {};
    // const checkForTawk = setInterval(() => {
    //   console.log(false);
    //   if (typeof Tawk_API.hideWidget === "function") {
    //     console.log(true);
    //     clearInterval(checkForTawk);
    //     Tawk_API.hideWidget();
    //   }
    // }, 200);
    //setTimeout(() => {
    // Tawk_API = Tawk_API || {};
    // console.log(Tawk_API);
    //window.Tawk_API.showWidget();
    //}, 2500);

    const carouselPaginator = document.querySelector(".VueCarousel-pagination");
    const carouselDotContainer = document.querySelector(
      ".VueCarousel-dot-container"
    );
    const titleElement = `<div class="carouselTitle" />`;
    carouselDotContainer.insertAdjacentHTML("afterbegin", titleElement);

    const callback = () => {
      // console.log("kör callback");
      const currentActiveSlider =
        document.querySelector(".VueCarousel-slide-active") ||
        document.querySelector(".VueCarousel-slide");
      const prevSlider = currentActiveSlider.previousSibling;
      const nextSlider = currentActiveSlider.nextSibling;
      if (currentActiveSlider) {
        nextSlider && nextSlider.classList.add("nextSlider");
        prevSlider && prevSlider.classList.add("prevSlider");
        document.querySelector(".carouselTitle").innerHTML =
          currentActiveSlider.getAttribute("data-name");
      }
    };

    const startMutationsObserver = () => {
      const target = document.querySelector(".VueCarousel-inner");
      const config = {
        attributes: true,
        childList: false,
        subtree: false,
      };
      const mutationObserver = new MutationObserver(callback);
      mutationObserver.observe(target, config);
    };

    startMutationsObserver();
  },
  beforeDestroy() {
    //window.Tawk_API.hideWidget();
  },
};
</script>
<style lang="scss">
.VueCarousel-pagination {
  background-color: #f5f5f6;
  font-size: 10px;
  .carouselTitle {
    margin-bottom: 10px;
  }
  .VueCarousel-dot-container {
    margin-bottom: 20px;
  }
  .VueCarousel-dot {
    padding: 0px !important;
    margin: 0px 6px !important;
    transition: none;
    border: 1px solid black !important;
    &:not(.VueCarousel-dot--active) {
      background-color: #f5f5f6 !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@include buttonStylesWithHoverEffect;
$maxWidthDesktop: 1280px;
$maxWidthInnerContentDesktop: 1040px;
a {
  @include Gilroy-Regular;
}
.row {
  margin: 0;
}
section {
  margin: 68px auto;
  text-align: center;
  &.withBackgroundColor {
    margin: 0px auto;
    padding-top: 68px;
    padding-bottom: 83px;
  }
}
h1,
h2,
h3,
h4,
h5,
p {
  color: $darkText !important;
  margin-bottom: 0px;
}
h1 {
  @include Gilroy-Bold;
  font-size: 64px;
  padding: 0;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 84px;
}
h2 {
  @include Gilroy-Bold;
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 0;
}
h3 {
  font-size: 32px;
  @include Gilroy-Regular;
  text-align: center;
  line-height: 48px;
  margin-bottom: 0;
}
h4 {
  font-size: 24px;
  line-height: 40px;
  @include Gilroy-Regular;
  margin-bottom: 0;
}
h5 {
  font-size: 20px;
  @include Gilroy-Regular;
  margin-bottom: 0;
  line-height: 30px;
}
h6 {
  font-size: 16px;
  @include Gilroy-Regular;
  margin-bottom: 0;
}
.boldMe {
  @include Gilroy-Bold;
}
.pageHero {
  max-width: $maxWidthDesktop;
  margin: 120px auto 0 auto;
}

.signUpButton {
  font-size: 16px;
  @include Gilroy-Bold;
  padding: 0 28px;
  height: 48px;
}
/* .signUpButton:hover {
  background-color: $shampoohover;
} */
.imageButtonSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.buttonSectionDeviceSpecific {
  display: flex;
  justify-content: center;
  a {
    display: flex;
    width: 100%;
    &:hover {
      color: white !important;
    }
    &.imageLink {
      img {
        align-self: center;
      }
    }
  }
}
.hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 0px;
  > * {
    margin-bottom: 48px;
  }
}
.buttonSection {
  justify-content: center;
  .buttonContainer {
    button {
      width: auto;
    }
  }
}
.carousel {
  display: none;
  .VueCarousel-slide {
    transition: transform 0.5s, opacity 0.5s;
    display: flex;
    justify-content: center;
    padding-top: 32px;
    align-items: flex-start;
    img {
      filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
      width: 58%;
      max-width: 350px;
    }
    &.nextSlider {
      transform: translateX(-30%);
      opacity: 0.5;
    }
    &.prevSlider {
      transform: translateX(30%);
      opacity: 0.5;
    }
  }
}
.scorecard {
  max-width: $maxWidthDesktop;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px auto 0 auto;
  position: relative;
  h3 {
    text-align: center;
  }
}
.scorecardImage {
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  .scorecardDesktop {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    width: 88%;
    margin-right: 12%;
    height: 100%;
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .scorecardMobile {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    position: absolute;
    width: 20%;
    bottom: 0px;
    right: 0;
  }
}
.appCTAWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightGray;
  > * {
    margin-bottom: 50px;
    text-align: center;
    max-width: $maxWidthInnerContentDesktop;
  }
  *:nth-last-child(1) {
    margin-bottom: 0px;
  }
  h2 {
    margin-bottom: 35px;
  }

  .storeButtonImage {
    width: 165px;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.025);
      box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.2);
    }
  }
}
.featureSectionWrapper {
  position: relative;
  z-index: 3;
  display: grid;
  justify-items: center;
  background-color: #fff;
  margin-bottom: 28px;
  * {
    text-align: center;
    max-width: 85%;
  }
  .featuresGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin-top: 95px;
  }
  .featureItem {
    display: grid;
    justify-items: center;
    margin-bottom: 70px;
    width: 100%;
    img {
      margin-bottom: 28px;
    }
    h3 {
      @include Gilroy-Bold;
      margin-bottom: 16px;
    }
  }
}
@media (max-width: $maxWidthDesktop) {
  .hero {
    width: 92%;
    margin: {
      left: auto;
      right: auto;
    }
  }
  .appCTAWrapper > * {
    max-width: 92%;
  }
}
@media (max-width: 992px) {
  .pageHero {
    margin-top: 32px;
  }
  .scorecard {
    display: none;
  }
  .carousel {
    display: block;
  }
}
@media (max-width: 828px) {
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  .hero {
    width: 90%;
    margin: 0 auto;
  }
  .scorecard {
    width: 90%;
    margin: 0 auto;
  }
  .featureSectionWrapper {
    margin-bottom: 56px;
    * {
      max-width: 95%;
    }
  }
}
@media (min-width: 767px) {
  .carousel {
    .VueCarousel-slide {
      &.nextSlider {
        transform: translateX(-40%);
      }
      &.prevSlider {
        transform: translateX(40%);
      }
    }
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
    line-height: 40px;
  }
  h2 {
    font-size: 26px;
    line-height: 32px;
  }
  h3,
  h4,
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
  p {
    font-size: 12px !important;
  }
  button {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  section {
    margin: 46px auto;
    &.withBackgroundColor {
      padding: {
        top: 46px;
        bottom: 46px;
      }
    }
  }
  .hero {
    display: block;
    width: 92%;
    padding-top: 12px;
    > * {
      margin-bottom: 26px;
    }
  }
  .appCTAWrapper {
    > * {
      margin-bottom: 32px;
    }
    p {
      max-width: 320px;
    }
  }
  .featureSectionWrapper {
    h1 {
      max-width: 278px;
      margin-left: auto;
      margin-right: auto;
    }
    .featuresGrid {
      grid-template-columns: 1fr !important;
      margin-top: 54px;
      .featureItem {
        &:not(:last-child) {
          margin-bottom: 54px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        img {
          width: 64px;
          margin-bottom: 22px;
        }
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 510px) {
  /* * {
    text-align: left !important;
  } */
}
</style>
